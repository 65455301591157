.top-baner{
    padding: 14px 10px 8px 40px;
    background-color: white;
    position: fixed;
    top: 0;
    /* left: max(min(14%, 200px), 140px); */
    /* left: 140px; */
    left: 300px;
    right: 0;
    max-height: 65px;
    border-bottom: 1px solid #ccc;
    z-index: 2;
}

h1{
    font-weight: 500;
}
.title{
    float: left;
    margin: 0;
    line-height: 1;
    font-family: headerFont;
    font-size: 1.5rem;
    padding: 5px;
    font-size: 1.5rem !important;
    color: #b79766 !important;
}
.top-baner .form-control{
    border: 2px solid rgb(140,140,141);
    font-size: 12px;
    margin-left: 20px;
    padding-top: 14px;
    border-radius: 10px;
    padding: 10px;
    text-align: left;
    padding-left: 30px;
}

.top-baner .personal-info{
    width: 150px;
    float: right;
    height: 30px;
    position: relative;
    /* overflow: hidden; */
}

.top-baner .personal-info .name{
    display: block;
    float: left;
    font-size: 12px;
    padding: 4px 10px;
    cursor: pointer;
}

.personal-info{
    position: relative;
    bottom : 28px;
}

.personal-info .dropdown-menu{
    position: absolute;
    top: 18px;
    font-size: 12px;
    padding: 0;
    padding-top: 20px;
}

.personal-info .nav-item{
    list-style: none;
}

.dropdown-toggle::after{
    display: block !important;
    position: absolute;
    margin-left: 32px !important;
    bottom: 20px;
}

.dropdown .dropdown-toggle{
    background-color: #fff;
    border: 0;
    position: relative;
    bottom: 5px;
    right: 9px;
}

.dropdown .dropdown-toggle:hover{
    background-color:#fff;
}

.dropdown .btn-primary, .bth-primary:hover{
    --bs-btn-color: black !important;
}
.main-body{
    position: relative;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
}
h3{
    font-size: 1.1em;
    font-weight: bolder;
    color: #000;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    line-height: 2 !important;
}

.row .form-group{
    position:relative;
    /* left : 50px; */
}

.longText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}