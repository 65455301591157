* {
    font-family: 'siteFont', 'Notosan', YuGothic, '游ゴシック', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', 'Meiryo', 'メイリオ', 'ＭＳ Ｐゴシック', 'ＭＳ ゴシック', Osaka, Osaka-等幅, sans-serif;
    color: #000;
}


/* Data Table */

/* .datatable .tss-1fz4yw6-MUIDataTableSelectCell-fixedLeft{
    position: sticky !important;
} */
/* .tss-1fz4yw6-MUIDataTableSelectCell-fixedLeft{
    position: unset !important;
} */
.datatable .css-11mde6h-MuiPaper-root{
    box-shadow: unset !important;
    border: 1px solid rgba(0, 0, 0, 0.125);
}
.datatable .css-1ex1afd-MuiTableCell-root{
    padding: 8px !important;
    /* padding-left : 3px !important; */
}
/* .tss-1acbflw-MUIDataTable-responsiveScrollMaxHeight{
    max-height: 600px !important;
} */
/* .datatable th .tss-1akey0g-MUIDataTableHeadCell-data{
    width: 20px;
} */

/* .datatable .tss-1fz4yw6-MUIDataTableSelectCell-fixedLeft{
    position: unset !important;
} */
thead button{
    text-align: left !important;
}

@media (max-width: 540px) {
    #search-autocomplete input[type="text"]{
        max-width: 90% !important;
        left: 10px !important;
        width: 100% !important;
        /* max-width: 50% !important; */
        position:relative;
        margin-left: 0px !important;
        margin-top: 18px;
        /* left: 20px; */
    }
    .top-baner, .top-baner-detail{
        left: 30px !important;
    }
    .top-baner .personal-info{
        margin-top: 15px; 
    }
}
@media (max-width: 720px) {
    .top-baner .personal-info{
        left : 70px;
        position:relative;
    }
   
}
@media (max-width: 820px) {
    .top-baner .form-control {
        /* max-width: 50% !important; */
        left: 23px;
        position:relative;
    }
}
@media (max-width: 984px) {
    .top-baner .form-control{
        width: 60% !important;
        margin-left: 50px;
    }
}
@media (max-width: 1140px) {
    .top-baner .form-control {
        max-width: 80% !important;
        /* margin-left: 50px; */
    }
}
@media (max-width: 1320px) {
    .top-baner .form-control {
        /* max-width: 85% !important; */
        /* margin-left: 50px; */
    }
}

@media (max-width: 1380px) {
    .top-baner .form-control {
        max-width: 85% !important;
        /* margin-left: 50px; */
    }
}


/* .tss-1h9t3sl-MUIDataTableHeadCell-sortAction{
    width: 207px;
} */

.MuiTable-root {
    border-collapse:collapse;
}
.MuiTable-root tr:nth-child(2n+1){
    background-color:rgb(242,242,242);
}
.MuiTable-root tr:nth-child(2n){
    background-color:rgb(255,255,255);
}

/* .MuiTable-root tr td {
border: 1px solid #C0C0C0;
} */

#other_input {
    margin-left: -85px !important;
    padding: 4px 8px !important;
}
.partner_search .col-3{
    margin-left: -18px;
}
table input[type=checkbox]{
    margin-right: unset !important;
    padding: 0px !important;
}
.css-12d3qmo-MuiDrawer-docked .MuiDrawer-paper{
    width: 250px !important;
    border-left: unset !important;
}
.css-1m2w22g-MuiPaper-root-MuiPopover-paper-MuiMenu-paper, .MuiPaper-elevation8.MuiMenu-paper{
    max-width: 60px !important;
    left: 70% !important;
    top: 32rem !important;
}
.css-10by3a5-MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem{
    font-size: 12px !important;
    font-family: 'siteFont', 'Notosan', YuGothic, '游ゴシック', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', 'Meiryo', 'メイリオ', 'ＭＳ Ｐゴシック', 'ＭＳ ゴシック', Osaka, Osaka-等幅, sans-serif;
}

.css-1uko9b2-MuiPaper-root{
    margin-top: 2% !important;
}

/* .tss-1dccma1-MUIDataTablePagination-toolbar{
    height: 37px;
} */

.tss-1dccma1-MUIDataTablePagination-toolbar {
    min-height: 33px !important;
}
.css-jtlhu6-MuiTablePagination-root{
    overflow: unset !important;
}
/* person list card design */
.tss-1hvope9-MUIDataTableHeadCell-root {
    background-color: rgb(249,249,249) !important;
    padding: 0px;
}
.tss-jcxdwk-MUIDataTableBodyCell-root {
    background: #fff !important;
}
.persons .card ul{
    list-style: none;
    margin-bottom: 5px !important;
}
.assign_btn,.btn_standard,.flit-right,.submit-btn{
    background-color: rgb(0,23,193) !important;
    border: 1px solid rgb(0,23,193) !important;
    padding: 2px 5px;
    border-radius: 10px;
    outline: 0;
    font-size: 12px;
    color: #fff !important;
    font-weight: bold;
}
.btn_standard_1{
    opacity: 0.5;
    background-color: grey !important;
    border: 1px solid grey !important;
    border-radius: 10px;
    outline: 0;
    font-size: 12px;
    color: #fff !important;
    font-weight: bold;
}

.work-count,.procedure_count{
    background-color: white !important;
    border: 0 !important;
    padding: 2px 5px !important;
    /* border-radius: 10px; */
    outline: 0 !important;
    font-size: 12px !important;
    font-weight: bold !important;
    color: #1D5577 !important;
    text-decoration: underline;
}

.work-table button:hover,.procedurelist-table button:hover{
    border: 0px !important;
}

.del-check{
    float: right;
    display: block;
    position: relative;
    left: 90px;
}


.count-card{
    background-color: #1D5577;
    padding: unset !important;
}
.count-col{
    border-bottom: 1px solid grey;
    color: white;
    text-align: center;
    font-weight: 500;
    font-size: 38px;
}
.count-name{
    color:white;
    text-align: center;

}
.card-link{
    text-decoration: none;
    color:white;
}
.chat-insert-profile{
    width: 100%;
    height: 50px;
    position: relative;
    display: inline-block;
    /* padding-top: 20px; */
    text-align: left;
}
.chat-insert-profile .show-text{
    border: none !important;
    font-size: 1.3rem;
    margin-left: 70px;
}
.chat-text,.edit-chat-text{
    width: 100%;
    border: 1px solid #eaeaea;
    padding: 20px !important;
    font-size: 14px;
    border: none !important;
}

.chat_title {
    font-weight: 600;
    color: #b79766;
}

.chat-comment-edit-box textarea{
    border-bottom: 1px solid #aeaeae !important;
    border-radius: 0px !important;
}

/* .chat-comment-edit-box textarea{
    width: 100%;
    border: 1px solid #eaeaea;
    padding: 20px;
} */


.chat-comment-box textarea, .reply_one_textarea{
    margin-left: 20px !important;
    width: 90% !important;
    border: 1px solid #eaeaea !important;
    padding: 20px !important;
}

.summary-block{
    margin-top: 1em;
}

.todolist{
    border: 1px solid #eaeaea;
    border-radius: 15px;
}

#add-text{
    float: right;
    margin-right: 20px;
}

#add-text::before {
    color: #fff;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    margin-right: 10px;
    background-size: 15px;
    display: inline-block;
    width: 15px;
    height: 15px;
    content: "";
    margin-bottom: -2px;
    font-size: 13px;
}

#add-text::before {
    background-image: url(/public/images/722263.png);
}

.chat-insert-profile::before {
    /* color: #251d1d; */
    position: absolute;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    margin-right: 10px;
    background-size: 100%;
    display: inline-block;
    width: 50px;
    height: 50px;
    content: "";
    margin-bottom: -2px;
    font-size: 13px;
    background-color: #eaeaea;
    background-color: #b5adad;
    border-radius: 50%;
    left: 10px;
    top: 10px;
}
.chat-insert-profile::before {
    background-image: url(/public/images/youinn.png);
}

.chat-insert-profile .show-text:focus,.chat-text:focus,.edit-chat-text:focus{
    outline :none;
    border: 0;
    box-shadow: none;
}

.attach-btn,.edit-attach-btn{
    padding: 5px;
    float: left;
    border: 0;
    background: unset;
}

.dashboard-header h2{
    font-size: 1.1em;
    line-height: 2;
    font-weight: bold;
    border-left: 5px solid #b79766;
    padding-left: 10px;
    text-align: left;
}
.button-send{
    background-color: #CB666A !important;
    color: white;
    border: 1px solid #CB666A;
    padding: 6px 20px;
    font-size: 12px;
    border-radius: 3px;
    text-decoration: none;
    text-align: center;
    margin-right: 30px;
    float: right;
}

.chat-footer{
    margin-top: 15px;
}

.block{
    display:block;
    font-size: 12px;
    color : black;
}

/* .container{
    overflow: scroll;
    max-height: 100vh;
} */

/* .img-responsive{
    background-image: url(/public/images/PMark_floboard_JP.jpg);
} */

.btn_danger{
    background-color: #CB666A;
    border: 1px solid #CB666A !important;
    padding: 2px 10px;
    border-radius: 10px;
    outline: 0;
    font-size: 12px;
    color: #fff !important;
    font-weight: bold;
}

.w-100{
    margin-top: 0;
    margin-bottom: 1rem;
    /* color: black !important; */
}

.policy-header{
    border-bottom: 5px solid #b79766;
    padding-left: 10px;
    text-align: left;
    line-height: 2;
}

.policy-header h2{
    font-size: 0.9em;
    font-weight: bold;
}

.list-text,.last,.text{
    color: black !important;
    font-size: 12px;
}

.w-30{
    width: 30%;
}

.body-text{
    color: black;
}

.graph h3{
    border-left: 5px solid #b79766;
    padding-left: 10px;
    text-align: left;
    font-size: 14px;
}

.list-style-img{
    list-style-image :url(/public/images/mark.png);
}

.btn_search{
    padding: 4px 3px 3px 3px;
}
.detail span a.btn_standard{
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 2px;
}
.profile .detail span a.btn_standard{
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-left: unset !important;
}
.table-bordered th{
    background-color: #444 !important;
    color: white !important;
}
.table-bordered tr td{
    font-size:12px !important;
}
.btn_select_all{
    font-size: 15px;
    color: #fff !important;
    padding: 10px;
    border-radius: 10px;
    background-color: #888888 !important;
    border: 1px solid #888888 !important;
    width : unset !important;
}
.btn_cancel, .modal_cancel{
    font-size: 15px;
    color: rgb(0,23,193) !important;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #fff !important;
    border: 1px solid rgb(0,23,193) !important;
}
.btn_cancel:hover{
    color: #fff;
    padding: 5px 15px;
    border-radius: 30px;
    background-color: rgb(0,23,193);
    border: 2px solid rgb(0,23,193);
    font-size: 12.5px;
}

.attention{
    color : red;
    font-size : 15px;
}

.search-txt{
    color : red;
}

.show_upload_file .image,.edit_show_upload_file .image{
    margin: 0px !important;
}

.fa-times-circle{
    color:red;
}

.show_upload_file,.edit_show_upload_file{
    text-align: left !important;
}

.chat-menu{
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
    border-bottom: 1px solid #eaeaea;
}

.chat-menu ul {
    list-style: none;
    margin: 0;
    width: 100%;
    display: inline-block;
    padding: 0;
}

.chat-menu ul li {
    float: left;
}

.chat-menu ul li a {
    text-decoration: none;
    display: block;
    padding-right: 20px;
    padding-left: 10px;
    border-bottom: 1px solid transparent;
}

#title-menu{
    float: right;
    margin-right: 20px;
}

#title-menu span {
    font-size: 12px !important;
    bottom: 50px;
    position: relative;
}

#title-menu .date::after,#reply_one_menu::before,#reply_two-menu::before{
    color: #000;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    display: inline-block;
    content: "...";
    margin-bottom: -2px;
    font-size: 25px;
    line-height: 1;
    margin-left: 10px;
    cursor:pointer;
}

#title-menu ul {
    position: relative;
    padding: 10px 0;
    list-style: none;
    background-color: white;
    /* border: 1px solid #eaeaea; */
    /* border-radius: 6px; */
    /* right: 10px; */
    left : 89px;
    z-index: 3;
    top: -50px;
    width: 60%;
}

#reply_one_menu,#reply_two-menu {
    float: right;
    margin-right: 20px;
}

#reply_two-menu ul,#reply_one_menu ul{
    list-style: none;
    padding: 10px 0;
    background-color: white;
    z-index: 3;
    display: block;
    margin-top: 0px;
}

#reply_one_menu ul li,#reply_two-menu ul li{
    font-size : 15px;
}

.chat-comment .reply_one_edit{
    display: block;
    position: relative;
    left: 250px;
}

#reply_one_menu ul{
    /* position: relative;
    list-style: none;
    display:block;
    padding: 10px 0; */
    /* background-color: white; */
    /* z-index: 3;
    margin-top: 0px;
    font-size: 12px; */
}

.loglist ul li:nth-child(even) {
    background-color: #eaeaea;
}

/* .date:hover + .show-title-menu {
    display: inline;
} */


/* #title-menu .date:hover + ul .show-title-menu {
    display: block;
  } */
.show-title-menu{
    margin-top: -1em !important;
}
.show-title-menu li{
    padding: 10px 20px !important;
}
#title-menu ul li,#reply_one_menu ul li{
    padding: 0 20px;
    text-align: left;
}

.comment-delete-btn{
    border: unset;
    padding: 0;
}

.chat-box{
    border: 1px solid #eaeaea;
    border-radius: 15px;
    margin-bottom: 30px;
}

.chart-container{
    display: block;
    box-sizing: border-box;
    bottom: 35px;
    height: 259px;
    width: 518px;
    right: 17px;
}

.purchaseOrderForm,.purchaseOrderForm label{
    font-size: 14px;
}

.purchaseOrderForm label{
    font-size: 13px;
    display: block !important;
}

/* .purchaseOrderForm input[type=text]{
    font-size:13px;
} */

.chat-head{
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #eaeaea;
    position: relative;
    height: 70px;
}
.loglist .chat-head{
    border-bottom: 0 none;
}

.loglist ul {
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: left;
}

.chat-image img {
    border-radius: 50%;
    width: 20px !important;
    height: 20px !important;
}

.loglist .chat-profile p, .loglist .chat-profile span {
    font-size: 12px;
    line-height: 1;
}

.loglist ul li{
    margin-bottom: 10px;
    position: relative;
    line-height: 1.5;
    padding: 10px;
}

.chat-body{
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #eaeaea;
    position: relative;
}

.edit-content {
    font-size: 12px !important;
    font-weight: normal !important;
}

.comment_pre {
    float: left;
    padding-left: 10px;
    text-align: left;
}

.chat-footer div {
    float: left;
    margin-right: 20px;
    text-decoration: underline;
}

.chat-like::before, .comment::before, .list::before,.activity::before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    margin-right: 10px;
    background-size: 15px;
    display: inline-block;
    width: 15px;
    height: 15px;
    content: "";
    margin-bottom: -2px;
    font-size: 13px;
}

.comment::before {
    content: "\f27a";
}

.chat-like::before {
    content: "\f164";
}

.list::before {
    content: "\f06e";
}

.chat-message::before {
    content: "\f086";
}

.chat-footer div {
    float: left;
    margin-right: 20px;
    text-decoration: underline;
}

.chat-footer div.cmt {
    margin-right: 0px !important;
}

.chat-footer div.chat-profile {
    text-decoration: unset !important;
}

.chat-comment-edit-box {
    display: none;
    border-bottom: 1px solid #aeaeae;
    border-top: 1px solid #aeaeae;
}

.button-container {
    margin: 0 auto;
    text-align: center;
    padding-top: 30px;
    display: inline-block;
    width: 100%;
}

.button-container .button {
    background-color: #1D5577;
    color: white;
    margin: 0 20px 0 20px;
    border: 1px solid #1D5577;
    padding: 10px 10px;
    font-size: 12px;
    border-radius: 6px;
    text-decoration: none;
    width: 130px;
    text-align: center;
}

.update_btn {
    float: right;
    margin: 0 10px 0 10px !important;
    padding: 5px !important;
    background-color: #CB666A !important;
    border: #CB666A !important;
}

.cancel_btn {
    float: right;
    margin: 0 10px 0 10px !important;
    padding: 5px !important;
    background-color: #1D5577;
    border: #1D5577;
}

.comment_box_footer{
    margin-bottom: 20px;
    padding-right: 3rem !important;
}

.reply_one_textarea {
    margin-left: 20px !important;
    width: 90% !important;
    font-size:small;
}

.chat-footer div.cmt {
    margin-right: 0px !important;
}

.chat-image {
    float: left;
    width: 50px;
    height: 50px;
    padding: 15px;
}

.chat-image img {
    /* width: 100%; */
    border-radius: 50%;
    width: 20px !important;
    height: 20px !important;
}

.chat-footer div.chat-profile {
    text-decoration: unset !important;
}

.chat-profile p {
    text-align: left;
    font-size: 1.3rem;
    color: black;
    font-weight: 600;
    position: relative;
    top: 8px;

}

.chat-profile span {
    font-size: 12px;
    float: left;
    position: relative;
    bottom: 13px;
}
.chat-profile .chat_title{
    margin-left: 4em;
}
.chat-activity{
    float: right !important;
}

.chatbox{
    width: 400px;
    height: 400px;
    margin: 0 20px 0 0;
    /* position: relative; */
    position: fixed;
    z-index: 1;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .2);
    display: flex;
    flex-flow: column;
    border-radius: 10px 10px 0 0;
    background: white;
    bottom: 0;
    transition: .1s ease-out;
}

.chatbox-top{
    position: relative;
    display: flex;
    padding: 15px 0;
    border-radius: 10px 10px 0 0;
    background: rgba(0, 0, 0, .05);
}

.chatbox-avatar {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    background: white;
    padding: 3px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .2);
    position: absolute;
    transition: .1s ease-out;
    bottom: 0;
    left: 6px;
}

.chatbox-avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 0;
    vertical-align: middle;
}

chat-partner-name{
    flex: 1;
    padding: 0 0 0 95px;
    font-size: 15px;
    font-weight: bold;
    color: #30649c;
    text-shadow: 1px 1px 0 white;
    transition: .1s ease-out;
}

.chatbox-icons{
    padding: 0 10px 0 0;
    display: flex;
    position: absolute;
    font-size: 10px;
    left: 35em;
    bottom: 15px;
}
.chatbox-icons .fa {
    background: rgba(220, 0, 0, .6);
    padding: 3px 5px;
    margin: 0 0 0 3px;
    color: white;
    border-radius: 0 5px 0 5px;
    transition: 0.3s;
}

.fa-close:before{
    content: "\f00d";
}

.chatbox-min {
    margin-bottom: -362px;
}

.chat-partner-name{
    flex: 1;
    padding: 0 0 0 95px;
    font-size: 15px;
    font-weight: bold;
    color: #30649c;
    margin-left : 100px;
    text-shadow: 1px 1px 0 white;
    transition: .1s ease-out;
}

 .chat-partner-name a,.chat-partner-name a:visited {
    color: black !important;
    text-decoration: none;
}

.chat-messages {
    border-top: 1px solid rgba(0, 0, 0, .05);
    padding: 10px;
    overflow: auto;
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    flex: 1;
}

.chat-input {
    resize: none;
    padding: 5px 10px;
    width: 80%;
    height: 40px;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    color: #999999;
    flex: 1;
    border: none;
    background: rgba(0, 0, 0, .05) !important;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
}

.chat-messages {
    border-top: 1px solid rgba(0, 0, 0, .05);
    padding: 10px;
    overflow: auto;
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    flex: 1;
}

.message-box-holder {
    width: 100%;
    margin: 0 0 15px;
    display: flex;
    flex-flow: column;
    align-items: flex-end;
}

.message-box, .message-sender {
    text-decoration: none !important;
}

.message-box {
    padding: 6px 10px;
    border-radius: 6px 0 6px 0;
    position: relative;
    background: rgba(100, 170, 0, .1);
    border: 2px solid rgba(100, 170, 0, .1);
    color: #6c6c6c;
    font-size: 12px;
}

.message-sender {
    font-size: 12px;
    margin: 0 0 15px;
    color: #30649c;
    align-self: flex-start;
  }

  .message-partner {
    background: rgba(0, 114, 135, .1);
    border: 2px solid rgba(0, 114, 135, .1);
    align-self: flex-start;
}

.chat-input-holder {
    display: block;
    border-top: 1px solid rgba(0, 0, 0, .1);
    background: rgba(0, 0, 0, .05) !important;
}

.chat-input-holder input[type="text"] {
    border-radius:0px !important;
    padding-left : 10px !important;
    border: none !important;
}

hr{
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #080202;
    margin: 1em 0;
    padding: 0;
}
.hr{
    height: 1px;
    border: 0;
    border-top: 1px solid #08020242;
    margin: 1em 0;
    padding: 0;
}

input:valid, textarea:valid {
    background-color: white;
}

input[type="submit"] {
    cursor: pointer;
    -webkit-appearance: button;
}

.message-send {
    background: linear-gradient(180deg, #00d8ff, #00b5d6);
    color: white;
    font-size: 12px;
    padding: 12.5px 24.2px;
    /* padding: 0 15px; */
    border: none;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
}

.profile_name{
    font-size: 1rem !important;
}

.modal_cancel_btn{
    background-color: #fff;
    color: rgb(0,23,193) !important;
    margin: 0 20px 0 20px;
    border: 1px solid rgb(0,23,193) !important;
    padding: 10px 10px;
    font-size: 12px;
    border-radius: 6px;
    text-decoration: none;
    width: 130px;
    text-align: center;
}

.modal_cancel_btn:hover{
    background-color: rgb(0,23,193);
    color: #fff !important;
    margin: 0 20px 0 20px;
    border: 1px solid rgb(0,23,193) !important;
    padding: 10px 10px;
    font-size: 12px;
    border-radius: 6px;
    text-decoration: none;
    width: 130px;
    text-align: center;
}

.btn_list_skill,.btn_list_skill:hover,.btn_cancel:hover{
    color: #fff;
    padding: 5px 15px;
    border-radius: 30px;
    background-color: rgb(0,23,193);
    border: 2px solid rgb(0,23,193);
    font-size: 12.5px;
}
a.btn_cancel:hover{
    color: #fff;
    padding: unset;
    border-radius: unset;
    background-color: rgb(0,23,193);
    border: 2px solid rgb(0,23,193);
    font-size: unset;
}
.btn-skill,.btn-skill:hover{
    color: rgb(0,23,193);
    padding: 5px 15px;
    border-radius: 30px;
    background-color: #fff;
    border: 1px solid rgb(0,23,193);
    font-size: 12.5px;
    /* font-weight: bold; */
}

.status-menu{
    display: inline-flex;
    list-style: none;
}
.status-menu li {
    padding: 2px 5px;
    cursor: pointer;
    font-size: 12px;
}
li.all,li.unavailable,li.available,li.not_start,li.assigning,li.proposaling,li.interview,
li.wait_result,li.not_public,li.otsu_not_public,li.otsu_public,
li.now_available,li.next_month_available,li.next_two_month_available,li.finish_release,
li.processing,li.not_assigning,li.not_proposaling,li.end,li.working,li.finished,li.release,
li.not_release,li.koukata_public,li.koukata_not_public,li.unit_price_set,li.全て,li.未着手,li.募集開始,
li.割り当てなし,li.割り当て中,li.未提案,li.提案中,li.面談設定中,li.結果待ち,li.募集終了,
li.稼働中,li.稼働終了,li.公開,li.未公開,li.甲方登録公開,li.甲方登録非公開,
li.募集開始,li.即日開始, li.翌月開始, li.翌々月開始 , li.not_available ,li.next_month_available,
 li.next_two_month_available
{
    background-color: rgb(0,23,193) !important;
    color: white !important;
    border: 1px solid rgb(0,23,193) !important;
}

#filter-by-skill label,#case-filter-by-skill label{
    padding-right: 20px;
    padding-left: 5px;
    font-size: 12px;
    top: 0px;
    position: relative;
}
.filter{
    padding: 0px !important;
}
.filter_select select{
    float: unset !important;
}
.filter_select label{
    font-size: 14px !important;
}
.search_result_num{
    margin-right: 1rem;
    font-size: 14px;
    font-weight: 500;
}
.pl_1{
    padding-left: 1.1rem !important;
}
.pl_5{
    padding-left: 4.5rem !important;
}
.pl_2{
    padding-left: 2.2rem !important;
}
.ml_20{
    margin-left: 20px !important;
}
.mb_25{
    margin-bottom: 25px !important;
}
.mb_1{
    margin-bottom: 1.2em !important;
}
.mr_2{
    margin-right: 2rem !important;
}
.mr_1{
    margin-right: 1rem !important;
}
.ml_1{
    margin-left: 1rem !important;
}
.case_btn_list button{
    width: 10em;
}
.filter_select 
.top-bar {
    width: 100%;
    height: 8px;
    border-radius: 20px;
    background-color: #444;
}
.persons .card, .cases .card{
    overflow: hidden;
    border-radius: 30px !important;
    box-shadow: unset !important;
}
.persons .card-body,.cases .card-body{
    padding: 0px;
}
.cases a{
    text-decoration: none !important;
}
.persons .card-footer,.cases .card-footer{
    background-color: #fff !important;
    border-top: unset !important;
}
.cases ul{
    padding-left: 1em !important;
    margin-bottom: 0 !important;
}
.cases .assign_btn{
    margin-right: 17px !important;
}
.modal_close{
    float: right !important;
    border: unset;
    background: #fff;
    padding:unset;
}
.profile, .case-info{
    padding: 1rem 1rem 0 1rem;
}
.profile img{
    border-radius: 50%;    
    width: 100px; 
    height: 100px;
}
.profile ul li span, .case-info ul li span{
    font-size: 12.5px;
}
.profile ul li span{
    font-size: 12px;
    text-decoration: none;
}
.case-info ul li{
    list-style: none;
}
.wariate-num {
    position: absolute;
    color: #fff;
    right: 5px;
    bottom: -2px;
    z-index: 1;
    font-size: 15px;
}
.wariate-aru {
    position: absolute;
    width: 60px;
    height: 60px;
    right: -30px;
    bottom: -30px;
    background-color: #b79766;
    transform: rotate(45deg);
}
.anken-add{
    float: right !important;
}
.anken-add a::before {
    content: "\f067";
    color: white;
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    margin-right: 10px;
    font-size: 20px;
}
.assign_btn::before {
    content: "\f4fc";
    color: white;
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    margin-right: 5px;
    font-size: 13px;
}
.download_btn{
    padding: 7px 5px;
}
.download_btn::before {
    content: "\f019";
    color: white;
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    margin-right: 5px;
    font-size: 15px;
    padding:30px 5px;
}
.download_btn:hover::before {
    content: "\f019";
    color: rgb(0,23,193);
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    margin-right: 5px;
    font-size: 13px;
}
.btn_standard_1.download_btn:hover::before {
    content: "\f019";
    color: #fff !important;
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    margin-right: 5px;
    font-size: 13px;
}

.person-lbl{
    font-size: 13px;
}

.download_btn::before {
    content: "\f019";
    color: white;
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    margin-right: 5px;
    font-size: 13px;
    padding:30px 5px;
}
.btn_add::before{
    content: "\f067";
    color: white;
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    margin-right: 3px;
    font-size: 14px !important;
}
.btn_add:hover::before{
    content: "\f067";
    color: rgb(0,23,193) !important;
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    margin-right: 3px;
    font-size: 15px !important;
}
.assign_btn:hover, .header-back-btn:hover, .btn_standard:hover,.btn_add:hover{
    background-color: #fff !important;
    border: 1px solid rgb(0,23,193) !important; 
    /* border: 0px !important; */
    color: rgb(0,23,193) !important;
}

.assign_btn:hover::before {
    content: "\f4fc";
    color: rgb(0,23,193);
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    margin-right: px;
    font-size: 13px;
}
.assign_count,.status_btn{
    font-size: 12px;
    color: #000 !important;
    padding: 3px 15px;
    border-radius: 10px;
    background-color: rgb(249,249,249) !important;
    border: 1px solid rgb(249,249,249) !important;
}
.status_btn_2{
    position: absolute;
    border-radius: 22px;
    /* right: 34px; */
    top: 113px;
    font-size: 8px;
    left: 42px;
    color: #fff !important;
    padding: 3px 15px;
    border-radius: 10px;
    background-color: #419B73 !important;
    border: 1px solid rgb(249,249,249) !important;
}

.status_btn_1{
    position: absolute;
    border-radius: 22px;
    top: 113px;
    font-size: 8px;
    left: 38px;
    color: #fff !important;
    padding: 3px 15px;
    border-radius: 10px;
    background-color:red !important;
    border: 1px solid rgb(249, 249, 249) !important;
}

.assign_count::before {
    content: "\f4fc";
    color: black;
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    margin-right: 10px;
    font-size: 15px;
}
/* Case Card Style */
.case-info ul li >span {
    PADDING: 3px 10px;
    border-radius: 5px;
    background-color: rgb(0,23,193);
    margin-right: 10px;
    color: white;
    min-width: 70px;
    display: inline-block;
    margin-bottom: 7px;
    text-align: center;
}
.case-info ul{
    padding: 0 !important;
}
.case-info p{
    font-size: 1.3em;
}
p.h4{
    font-weight: bold;
    font-size:  15px;
}
.check-list input[type=checkbox], #overtime_flg{ 
    -webkit-appearance: none;
    background-color: #fff;
    border: 2px solid rgb(140,140,141);
    padding: 2px 5px 5px 2px;
    border-radius: 0px;
    display: inline-block;
    position: relative;
    margin-right: 3px !important;
} 


.check-list input[type=checkbox]:checked {
	background-color: rgb(0,23,193);
	border: 1px solid rgb(0,23,193);
	color: #99a1a7;
}
.check-list input[type=checkbox]:checked:after {
    content: '\2714';
    font-size: 5px;
    position: absolute;
    top: -5px;
    left: 1px;
    color: #fff;
    padding-top: 4px;
}
.check-list select.filter{
    width: 100px;
}
/* form style */
.top-baner,.top-baner-detail{
    padding: 14px 10px 8px 40px;
    background-color: #fff !important;
    position: fixed;
    top: 0;
    /* left: max(min(14%, 200px), 140px); */
    /* left: 140px; */
    left: max(min(20%, 250px), 140px);
    right: 0;
    max-height: 65px;
    border-bottom: 1px solid #ccc;
    z-index: 2;
}
#search-autocomplete::after {
    color: rgb(140,140,141);
    content: "\f002";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 15px;
    float: right;
    padding: 10px;
    margin-top: -2.7rem;
    display:none;
    /* position: absolute; */
    /* left: 75em;
    top: 25px; */
}
.css-1l942k8{
    width: 280px !important;
}
.css-wnh6mk{
    width: calc(100% - 280px) !important;
}
.tss-gm6zfk-MUIDataTableHeadCell-fixedHeader{
    /* top :unset !important; */
}
/* .css-1ygcj2i-MuiTableCell-root,.tss-ylo811-MUIDataTableSelectCell-headerCell{
    background-color: rgb(193,193,193) !important;
    letter-spacing: unset !important;
    line-height:  unset !important;
} */
.css-1waayal-MuiButtonBase-root-MuiListItemButton-root{
    margin: 0;
    font-family: "siteFont","Notosan",YuGothic,"游ゴシック","Hiragino Kaku Gothic Pro","ヒラギノ角ゴ Pro W3","Meiryo","メイリオ","ＭＳ Ｐゴシック","ＭＳ ゴシック",Osaka,Osaka-等幅,sans-serif !important;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}
.css-1waayal-MuiButtonBase-root-MuiListItemButton-root{ 
    border-right: unset !important;
}
.MuiListItemButton-root,.MuiTypography-root{
    border-right: unset !important;
    font-size: 15px !important;
    font-family: "siteFont","Notosan",YuGothic,"游ゴシック","Hiragino Kaku Gothic Pro","ヒラギノ角ゴ Pro W3","Meiryo","メイリオ","ＭＳ Ｐゴシック","ＭＳ ゴシック",Osaka,Osaka-等幅,sans-serif !important;
}
p.MuiTypography-root{
    font-family: "siteFont","Notosan",YuGothic,"游ゴシック","Hiragino Kaku Gothic Pro","ヒラギノ角ゴ Pro W3","Meiryo","メイリオ","ＭＳ Ｐゴシック","ＭＳ ゴシック",Osaka,Osaka-等幅,sans-serif !important;
}
.css-zylse7-MuiButtonBase-root-MuiIconButton-root{
    border-radius: unset !important;
    background-color: #c1c1c1 !important;
}
/* 
.css-1mk9mw3-MuiList-root::before{
    content: "SESMart" !important;
    font-size: 2em !important;
    color: rgb(0,23,193) !important;
    font-weight: bold;
    text-align: center !important;
    padding: 0px 10px 0px 50px;
} */
.MuiTableCell-footer{
    padding: 0px !important;
}
#pagination-back.Mui-disabled,
#pagination-next.Mui-disabled{
    /* display: none !important; */
    color: white !important;
    background-color: #f2f2f2 !important;
}
#pagination-back.Mui-disabled .MuiSvgIcon-root path,
#pagination-next.Mui-disabled .MuiSvgIcon-root path{
    /* display: none !important; */
    color: grey !important;
}
table .MuiSvgIcon-root{
    width: 0.5em !important;
}
.MuiTablePagination-displayedRows{
    padding-top: 1rem;
}
.MuiTablePagination-selectLabel{
    display: none;
}
.MuiTablePagination-displayedRows::before{
    content: "件表示" !important;
    /* font-size: 3em !important; */
    /* color: rgb(0,23,193) !important; */
    text-align: center !important;
    padding-right: 2em;
    margin-left: -30px;
}
/* .floboard_logo::after{
    content: "Floboard" !important;
    font-size: 2em !important;
    color: #000 !important;
    font-weight: bold;
    text-align: center !important;
    padding: 0px 20px 0px 25px;
} */
/* .css-h7k7zv-MuiButtonBase-root-MuiListItemButton-root::after{
    content: "\f054";
    font-family: 'Font Awesome 5 Free';
    font-weight: bold;
    text-align: center !important;
    padding: 0px 20px 0px 25px;
} */
/* .css-h7k7zv-MuiButtonBase-root-MuiListItemButton-root svg{
    display: none;
} */
h1{
    font-weight: 500;
}
.title{
    float: left;
    margin: 0;
    line-height: 1;
    font-family: headerFont;
    font-size: 1.5rem;
    padding: 5px;
    font-size: 1.5rem !important;
    color: #b79766 !important;
}
.searchbox{
    width: min(calc(100% - 150px - 140px - 50px), 800px);
    border: 1px solid #C2C2D0;
    font-size: 12px;
    border-right: 1px solid #C2C2D0;
    margin-left: 20px;
    padding-top: 14px;
    border-radius: 10px;
    padding: 5px;
    text-align: left;
    padding-left: 30px;
    margin-top: 3px;
}
.top-baner .personal-info,.top-baner-detail .personal-info{
    width: 150px;
    float: right;
    height: 30px;
    position: relative;
    /* overflow: hidden; */
}
.personal-info .dropdown-menu{
    background-color: #fff;
    border: 2px solid rgb(140,140,141);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show{
    color: #000 !important;
}
/* .personal-info .dropdown-item:hover{
    background-color: #fff !important;
    color: #fff !important;
    text-decoration: none;
    border-bottom: 3px solid #A3A0FB;
} */
.top-baner .personal-info .name,.top-baner-detail .personal-info .name{
    display: block;
    float: left;
    font-size: 12px;
    padding: 4px 10px;
    cursor: pointer;
}

.personal-info{
    position: relative;
    bottom : 28px;
}

.personal-info .dropdown-menu{
    position: absolute;
    top: 18px;
    font-size: 12px;
    padding: 10px;
}

.personal-info .nav-item{
    list-style: none;
}

.dropdown-toggle::after{
    display: block !important;
    position: absolute;
    margin-left: -15px !important;
    bottom: 23px;
}

.dropdown .dropdown-toggle{
    background-color: #fff;
    border: 0;
    position: relative;
    bottom: 5px;
    right: 9px;
}
.w_100{
    width: 80px !important;
}

.dropdown .dropdown-toggle:hover{
    background-color:#fff;
    color:#000 !important;
}
.css-1aech5l-MuiButtonBase-root-MuiListItemButton-root{
    background-color: rgb(0,23,193) !important;
    border: 1px solid rgb(0,23,193) !important;
    color: #fff !important;
}
/* .dropdown .btn-primary, .bth-primary:hover{
    --bs-btn-color: black !important;
} */
.main-body{
    position: relative;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
}
select,input[type=text],
input[type=number],
input[type=time], 
input[type=password],
input[type=email],
textarea{
    border-radius: 10px !important;
    padding: 5px 15px 5px 15px;
    border: 1px solid rgb(83,83,85) !important;
}
label.file {
    padding: 7px 20px;
    font-size: 12px;
    color: #ffffff;
    background-color: rgb(0,23,193);
    cursor: pointer;
    border-radius: 6px;
}

input[type="file"] {
    display: none;
}

select.filter, select#sort, select#list_length{
    /* padding: 2px 5px 2px 5px !important; */
    padding: 5px 10px 5px 5px !important;
    margin-left: 0.25rem !important;
    margin-right: 1rem;
}
select.list_length{
    margin-right: 1px !important;
}
.react-datepicker-wrapper input[type=text],
.react-datepicker-wrapper input[type=text]:focus,
.react-datepicker-wrapper input[type=text]:active,
.react-datepicker-wrapper input[type=text]:hover,
.react-datepicker-wrapper input[type=text]:focus-visible
{
    padding: 2px 15px !important;
    font-size: 14px;
    margin-left : 5px;
    border: 1px solid rgb(83, 83, 85) !important;
    outline : unset !important;
}

.create-form .react-datepicker-wrapper input[type=text],
.create-form .react-datepicker-wrapper input[type=text]:focus,
.create-form .react-datepicker-wrapper input[type=text]:active,
.create-form .react-datepicker-wrapper input[type=text]:hover,
.create-form .react-datepicker-wrapper input[type=text]:focus-visible
{
    margin-left : 0px !important;
}
.assignment_add select{
    padding-left: 15px !important;
}
.pull-right{
    color: rgb(0, 23, 193) !important;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #fff !important;
    border: 1px solid rgb(0, 23, 193) !important;
}
.create-form .react-datepicker-wrapper input[type=text]{
    /* padding: 8px 30px  8px 15px !important; */
    width: 100%;
}
.create-form .react-datepicker-wrapper, .create-form .react-datepicker__tab-loop{
    display: block !important;
}
.create-form .react-datepicker-ignore-onclickoutside{
    border: 1px solid grey !important;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
    color : grey !important;
}
.react-datepicker__month-dropdown-container select,.react-datepicker__year-dropdown-container select{
    padding: unset !important;
}
.sign-in input[type=text]{
    float: unset !important;
}
/* .react-datepicker__input-container{
    position: absolute !important;
    display: unset !important;
    width: 88% !important;
    padding: 10px 15px !important;
} */
select option:hover{
    background: rgb(83,83,85) !important;
    border: 2px solid rgb(83,83,85) !important;
}
select:focus-visible{
    border:1px solid rgb(83,83,85) !important
}
h3{
    font-size: 1.1em;
    font-weight: bolder;
    color: #000;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    line-height: 2 !important;
}

/* .row .form-group{
    position:relative;
    left : 50px;
} */

/* span.space {
    padding-left: 15px;
} */
span.space {
    padding-left: 10px;
}
/* menu style */
.MuiDrawer-docked .MuiDrawer-paper{
    border-right: 1px solid rgb(226,226,228) !important;
}
.MuiDrawer-docked .MuiDrawer-paper, 
.MuiButtonBase-root-MuiListItemButton-root{
    background-color: #ffffff !important;
    border-right: 1px solid rgb(226,226,228) !important;
    color: #000000 !important;
}
.MuiListItemIcon-root{
    color: rgb(83,83,85) !important;
}

.css-h7k7zv-MuiButtonBase-root-MuiListItemButton-root:hover,
.css-1aech5l-MuiButtonBase-root-MuiListItemButton-root:active {
    background-color: #ffffff !important;
}

.css-h7k7zv-MuiButtonBase-root-MuiListItemButton-root,
.css-1waayal-MuiButtonBase-root-MuiListItemButton-root{
    border-bottom: 1px solid rgb(226,226,228) !important;
}
.css-wnh6mk{
    background-color: #fff !important;
}
.css-1vxbjdn-MuiToolbar-root{
    /* display: none !important; */
    padding-left: unset;
    padding-right: unset;
    min-height: unset !important;
    margin-top:-10px !important;
}
/* .css-1vxbjdn-MuiToolbar-root::before{
    content: "株式会社FloBoard" !important;
    font-size: 1em !important;
    color: rgb(0,23,193) !important;
    font-weight: bold;
    text-align: center !important;
    padding: 0px 10px 0px 30px;
    
} */
.css-19vx8qo-MuiStack-root{
    display: none !important;
}
/* case create style */

#button-addon2{
    padding: 4px;
    height: 30px;
    margin-top: 7px;
    margin-left: -10px;
    font-size : 12px;
}
#case_create_submit, #case_copy_delete{
    background-color: #1D5577;
    color: white;
    margin: 0 20px 0 20px;
    border: 1px solid #1D5577;
    padding: 10px 10px;
    font-size: 12px;
    border-radius: 6px;
    text-decoration: none;
    width: 130px;
    text-align: center;
}
#title{
    font-weight: 600;
    font-size: 14px;
}
#star{
    color:red;
    display: unset !important;
    /* padding-left: 10px; */
    font-size: small;
}
#text,.text{
    font-weight: 600 !important;
    font-size: 13px !important;
}
.form-select-sm{
    font-size: 13px;
}
#cancel{
    color: white;
    margin: 0 20px 0 20px;
    border: 1px solid #1D5577;
    padding: 10px 10px;
    font-size: 12px;
    border-radius: 6px;
    text-decoration: none;
    width: 130px;
    text-align: center;
    background-color: #A5A4BF;
    border: 1px solid #A5A4BF;
    padding: 10px 60px;
}

a.header-back-btn,a.btn_add {
    text-decoration: none;
}



/* case detail style */
.new-table {
    border-collapse:collapse;
    width: 100%;
}
.new-table tr:nth-child(2n+1){
    background-color:rgb(255,255,255);
}
.new-table tr:nth-child(2n){
    background-color: rgb(249,249,249);
}

/* .new-table tr.start1:nth-child(2n+1){
    background-color: rgb(249,249,249);
}
.new-table tr.start1:nth-child(2n){
    background-color:rgb(255,255,255);
} */

.case_assign_list .case_list:nth-child(2n+1){
    background-color:rgb(255,255,255);
}
.case_assign_list .case_list:nth-child(2n){
    background-color: rgb(249,249,249);
}


.result span{
    width: unset !important;
    margin-right: 4rem !important;
}
.result label{
    display: block;
}

.mt_2{
    margin-top: 1.5rem !important;
}
.mt_3{
    margin-top: 2.5rem !important;
}

.header-back-btn {
    float: right;
    background-color: rgb(0,23,193) !important;
    border: 1px solid rgb(0,23,193) !important;
    padding: 2px 5px;
    border-radius: 10px;
    outline: 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff !important;
}
.header-back-btn-white {
    float: right;
    background-color: #fff !important;
    border: 1px solid rgb(0,23,193) !important;
    padding: 2px 5px;
    border-radius: 10px;
    outline: 0;
    font-size: 12px;
    font-weight: bold;
    color: rgb(0,23,193) !important;
    width: 70px;
    text-align: center;
}
.header-back-btn-white:hover {
    float: right;
    background-color: rgb(0,23,193) !important;
    border: 1px solid rgb(0,23,193) !important;
    padding: 2px 5px;
    border-radius: 10px;
    outline: 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff !important;
}
a.header-back-btn-white{
    text-decoration: none;
}
.btn_add {
    float: right;
    background-color: rgb(0,23,193) !important;
    border: 1px solid rgb(0,23,193) !important;
    padding: 0px 5px;
    border-radius: 10px;
    outline: 0;
    font-size: 12px;
    color: #fff !important;
}
.top-bar {
    width: 100%;
    height: 8px;
    border-radius: 20px;
    background-color: #444;
}
.basic-info h3{
    border-left: 5px solid #b79766;
    padding-left: 10px;
}
.toriate-btn-container {
    display: block;
    text-align: right;
    margin-top: 16px;
}
.main-body section {
    padding-right: 0 !important;
    width: 100% !important;
    padding-top: 0 !important;
    margin-bottom: 20px;
}
body {
    font-family: "siteFont","Notosan",YuGothic,"游ゴシック","Hiragino Kaku Gothic Pro","ヒラギノ角ゴ Pro W3","Meiryo","メイリオ","ＭＳ Ｐゴシック","ＭＳ ゴシック",Osaka,Osaka-等幅,sans-serif !important;
    color: #656363;
    line-height: 2 !important;
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    background-color: #fff;
}
/* 
.main-container .anken-detail{
    width: 100%;
} */
.anken-detail .new-table {
    width: 100%;
    margin-bottom: 20px;
}
.new-table th{
    width: 30% !important;
}
table th{
    color: #000 !important;
}
th{
    padding: 0 5px;
}
tr th{
    white-space: nowrap;
    text-align: left;
    padding-left: 8px;
    font-weight: bold;
    font-size: 13px !important;
}
/* td {
    vertical-align: top;
} */
tr td{
    text-align: left;
    padding-left: 8px;
    font-weight: regular;
    font-size: 13px !important;
    /* white-space: nowrap; */
}
.css-1w1rijm-MuiButtonBase-root-MuiButton-root{
    font-size: 13px !important;
}


.toriate-btn-container {
    display: block;
    text-align: right;
    margin-top: 16px;
}
.container h3{
   font-size: 20px !important;
   color: rgb(49,49,51) !important;
}
.container h4{
    font-size: 17px !important;
    color: rgb(49,49,51) !important;
 }

.toriate-btn.blue {
    background-color: #1D5577;
}

.toriate-btn {
    text-decoration: none !important;
}

.anken-detail .span-title{
    display: none;
}
.detail .header-back-btn {
    margin-left: 1em;
    width: 70px;
    text-align: center;
    margin-top: 3px;
}
.create-form  span,.detail span {
    display: inline-block;
    /* width: 100%; */
    font-weight: 600;
    font-size: 13px;

    /* font-size: 15px; */
    line-height: 1.5;
}
.detail span.badges{
    background-color: rgb(0, 23, 193) !important;
    border: 1px solid rgb(0, 23, 193) !important;
    padding: 5px 15px;
    border-radius: 10px;
    outline: 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff !important;
    position: relative;
    bottom: 15px;
}
.badges.green{
    background: rgb(37,157,99);
}
.badges.blue{
    background: rgb(0,0,130);
}
.detail .profile img{
    border-radius: 50%;    
    width: 120px !important;
    height: 120px !important;
    padding: 0 !important;
}
.css-hgafxn{
    background: #fff !important;
}
.create-form .card,.detail .card{
    background: rgb(249,249,249);
    border-radius: 20px;
    padding: 20px;
    /* border: unset !important; */
}
.create-form .card{
    padding-left: 8em;
    padding-right: 8em;
}

.float-right {
    float: right;
}

button.assignment-edit-by-case {
    background-color: #444;
    border: 1px solid #444;
    padding: 1px 10px;
    border-radius: 2px;
    outline: 0;
    color: white;
    font-size: 10px;
    margin-right: 10px;
}
.delete-assignment{
    padding: 5px 10px !important;
}
.btn-finish {
    background-color: #444;
    border: 1px solid #444;
    padding: 1px 10px;
    border-radius: 2px;
    outline: 0;
    color: white;
    font-size: 10px;
    margin-right: 10px;
}
.btn-delete {
    background-color: #444;
    border: 1px solid #444;
    padding: 1px 10px;
    border-radius: 2px;
    outline: 0;
    color: white;
    font-size: 10px;
    margin-right: 9px;
}

.button-container .button-blue {
    background-color: #b79766;
    color: white;
    margin: 0 20px 0 20px;
    border: 1px solid #b79766;
    padding: 10px 0;
    font-size: 12px;
    border-radius: 6px;
    text-decoration: none;
    width: 100px;
    text-align: center;
}

.toriate-btn.red {
    background-color: #CB666A;
}

/* person detail style */
label, select {
    color: #000;
}
label{
    font-weight: 600;
    font-size: 15px;
}
select{
    font-size: 14px !important;
}

.create-form select, 
.create-form .react-datepicker-wrapper input[type=text],
.create-form .form-control{
    font-size: 13px !important;
}

.create-form .horizontal input[type=text]{
    padding: 1px 10px !important;
}

.login-container a, .two-factor-form a{
    color : #1D5577 !important;
}
.login-container .form-control{
    font-size: 14px;
    width:100%;
}
.login-container form input[type="text"] ,
.login-container form input[type="password"],
.two-factor-form form input[type="text"] ,
.two-factor-form form input[type="password"]{
    border: 1px solid transparent !important;
    border-bottom: 1px solid gray !important;
    border-radius: unset !important;
    margin-bottom: 25px;
    font-size: 12px;
    padding: 20px 0; 
}
.two-factor-form form .form-control, .login-container form .form-control{
    font-size: 15px !important;
    background-color: whitesmoke !important;
    padding: 13px 13px 13px 0px !important;
    margin-bottom: 25px !important;
}
.two-factor-form form .form-control:focus{
    color: unset;
    box-shadow: unset;
}
.two-factor-form .submit-btn, .login-container form .submit-btn{
    /* background-color: #444444ab !important; */
    border: 1px solid #444444ab !important;
    color: #fff !important;
    font-size: 15px !important;
}
.form-control{
    font-size: 14px;
}
h4{
    color: #222;
    font-size: 12px;
    line-height: 2;
    font-weight: 600;
}

#type{
    width: 88%;
    padding: 5px;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
}

.submit-btn{
    background-color: #1D5577;
    color: white;
    margin: 0 20px 0 20px;
    border: 1px solid #1D5577;
    padding: 10px 10px;
    font-size: 12px;
    border-radius: 6px;
    text-decoration: none;
    width: 130px;
    text-align: center;
}
.input-block{
    font-size: 12px;
}

.toriate-btn.blue {
    background-color: #1D5577;
    margin-top : 3px;
    /* margin-right: 17px; */
}
.toriate-btn {
    float: right;
    /* margin-right: 30%; */
    text-decoration: none;
    padding: 1px 5px;
    border-radius: 10px;
    /* background-color: blue; */
    background-color: #888888;
    color: white !important;
    font-size: 12px;
    text-align: center;
}
.toriate-btn.red{
    background-color: #CB666A;
    margin-right: 17px;
}
.container li{
    font-weight: 500;
    font-size: 12px;
    list-style-type: none;
    color: #000;
}
.card-list span{
    display: inline-block;
    width: 100%;
    font-weight: 600;
    font-size: 12px;
    color: #000 !important;
}
u{
    display: inline-block;
    border: 1px dashed;
    width: 100%;
    color: #656363;
}
.personal-wariate-addition h3{
    border-left: 5px solid #b79766;
    padding-left: 10px;
}
.selectbox{
    margin-right: 81%;
}

.eye-icon{
    display: block;
    float: right;
    position: relative;
    bottom: 24px;
    right: 10px;
}

.tss-ylo811-MUIDataTableSelectCell-headerCell{
    border: 1px solid rgb(242, 242, 242);
}

.mt_17{
    margin-top: 1.7rem !important;
}

/* 要員提案Modalのデザイン*/
/* .css-1i7fg01,.MuiBox-root{
    overflow: scroll;
} */
.MuiBox-root {
    overflow-x: scroll;
}

.input-required::after{
    content: "*";
    color: red;
}
.horizontal-select{
    width: 74%;
    margin-left: 20px;
    display: inline-block;
    /* padding: 5px !important; */
}
.horizontal-input{
    width: 78%;
    margin-left: 20px;
    font-size: 13px;
    display: inline-block;
    padding: 6px 15px !important;
}
/* .horizontal input[type=text]{
    padding: 1px 15px !important;
} */

.header h1{
    color: #b79766 !important;
    letter-spacing: 3px;
    font-size: 35px;
    font-weight: bold;
}

.two-factor-header h1{
    color: #b79766 !important;
    letter-spacing: 3px;
    font-size: 20px;
    font-weight: bold;
}

.header p{
    font-size: 13px;
    color: black;
    font-weight: bold;
}
.login-btn{
    background-color:  #444444ab;
    border: 1px solid  #444444ab;
    padding: 10px 10px;
    border-radius: 10px;
    outline: 0;
    font-weight: bold;
    width: 130px;
    text-align: center;
    color: #fff !important;
    font-size: 15px !important;
    margin: 0 20px 0 20px;
}


/* .security a{
    text-decoration: none;
} */
.login-error,.err_txt,.resend{
    color: red;
    font-size: 12px;
}
.success_txt{
    color: #155724;
    font-size: 12px;
}
.filter_select .react-datepicker-wrapper{
    display: inline-block !important;
}
.filter_select .react-datepicker-wrapper input[type=text]{
    padding: 4px 15px !important;
}
.check-list .react-datepicker-wrapper{
    display: inline-block;
}
.check-list .react-datepicker-wrapper input[type=text]{
    padding: 2px 5px !important;
    font-size: 12px;
}
.error, .show_error{
    color: red;
    margin-top: 7px;
}
.fc-col-header thead{
    background: #444 !important;
}

#drop_file_zone {
    background-color: #EEE;
    border: #999 5px dashed;
/*    width: 290px;
*/    height: 200px;
    padding: 8px;
    font-size: 18px;
}
#drag_upload_file {
  width:50%;
  margin:0 auto;
}
#drag_upload_file p {
  text-align: center;
}
#drag_upload_file #selectfile ,#drag_upload_file #editselectfile {
  display: none;
}

.edit-attach-btn {
    padding-left: 20px;
}
#profile_photo,#selectprofile{
  display: none;
}
.img-content img {
  margin-left: auto !important;
  margin-right: auto !important;
  display: block !important;
  width: 100px !important;
  height: 100px !important;
  border-radius: 50% !important;
}
.profile_upload #drag_upload_file {
  font-size: 12px !important;
}
.profile_upload #drop_file_zone {
  height: unset;
}
.img-content  {
  text-align: center !important;
}
.show_profile img{
  z-index: 0;
  position: relative;
}
.show_profile button{
  z-index: 1;
  margin-left: 50px;
  padding: 0px;
  /*position: absolute;
  margin-top: -5px;*/
}
.show_profile i{
    color: red;
}
.modal_cancel {
    padding: 10px 10px;
    font-size: 12px;
    border-radius: 6px;
    text-decoration: none;
    width: 130px;
    text-align: center;
    background-color: #fff !important;
    border: 1px solid rgb(0, 23, 193);
    color :rgb(0, 23, 193);
}

.modal_cancel:hover{
    background-color: rgb(0, 23, 193) !important;
    border: 1px solid rgb(0, 23, 193);
    color: #fff !important;
}

@media (min-width: 1500px) {
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1550px !important;
    }
 
    .horizontal-input{
        width: 72% !important;
    }
    /* .detail .profile .status_btn_1, .detail .profile .status_btn_2 {
        left: 355px !important;
    } */
}
@media (min-width: 1800px){
    .detail .profile .status_btn_1, .detail .profile .status_btn_2 {
        left: 355px !important;
    }
    .sub-status-menu {
        left: 405px !important;
    }
    .horizontal-input{
        width: 78% !important;
    }
}

.css-1w1rijm-MuiButtonBase-root-MuiButton-root{
    padding: 6px !important;
}

.MuiTablePagination-actions .css-i4bv87-MuiSvgIcon-root path{
 color:grey;
}

.MuiTablePagination-actions .MuiSvgIcon-root path{
    border: 1px solid rgb(0, 23, 193) !important;
    /* color: rgb(193, 193, 193) !important; */
    color: rgb(0, 23, 193) !important;
}

.previous-page-icon,.next-page-icon{
    color:rgb(193, 193, 193) !important;
}

#previous-page-icon.show-color {
    color: #ff0000; 
}
/* .fa-chevron-left,.fa-chevron-right{
    color:rgb(193, 193, 193) !important;
    
}
.fa-chevron-left :disabled{
    color:rgb(193, 193, 193);
} */

.MuiSvgIcon-root path{
    border: 1px solid rgb(0, 23, 193) !important; 
    color: rgb(0, 23, 193) !important; 
}
.MuiTablePagination-actions .css-i4bv87-MuiSvgIcon-root{
    height: unset !important;
    font-size: 4rem !important;
}
.MuiTablePagination-actions #pagination-back,
.MuiTablePagination-actions #pagination-next {
    padding: 0px !important;
}

.tss-1dccma1-MUIDataTablePagination-toolbar {
    max-height: 25px;
}

.tss-1fbujeu-MUIDataTableHeadCell-toolButton {
    height : 25px;
}
#pagination-back.Mui-disabled{
    width: 27px;
} 
/* .css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled {
    width: 27px;
} */
.tss-178gktx-MUIDataTableHeadCell-contentWrapper{
    height : 15px;
}


.longText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* freeze work-list,assignment-person-list */
.freeze-header .MuiTableHead-root th:nth-child(1),
.freeze-cell .MuiTableCell-root:nth-child(1),
.assign-per-freeze-header .MuiTableHead-root th:nth-child(1),
.assign-per-freeze-cell .MuiTableCell-root:nth-child(1),
.freeze-sale-header .MuiTableHead-root th:nth-child(1),
.freeze-sale-cell .MuiTableCell-root:nth-child(1),
.assign-case-freeze-header .MuiTableHead-root th:nth-child(1),
.assign-case-freeze-cell .MuiTableCell-root:nth-child(1),
.assign-approve-freeze-header .MuiTableHead-root th:nth-child(1),
.assign-approve-freeze-cell .MuiTableCell-root:nth-child(1){
    position: sticky !important;
    left: 0;
    z-index: 4;
    min-width: 46px;
    /* min-width: 34px; */
    /* margin-left:50px; */
}

.freeze-cell .MuiTableCell-root:nth-child(1),
.assign-per-freeze-cell .MuiTableCell-root:nth-child(1)
.freeze-sale-cell .MuiTableCell-root:nth-child(1){ 
    z-index: 0;
}



.freeze-header .MuiTableHead-root th:nth-child(2),
.freeze-cell .MuiTableCell-root:nth-child(2){
    position: sticky;
    left: 46px;
    /* left: 34px; */
    /* z-index: 3; */
    min-width: 113px;
    /* border: 1px solid grey !important; */
    /* min-width: 103px; */
    /* padding-left : 25px; */
}

.assign-per-freeze-header .MuiTableHead-root th:nth-child(2),
.assign-per-freeze-cell .MuiTableCell-root:nth-child(2){
    position: sticky;
    /* left: 46px; */
    left: 46px;
    z-index: 3;
    min-width: 113px;
}

.case-freeze-cell .MuiTableCell-root:nth-child(2),
.assign-per-freeze-cell .MuiTableCell-root:nth-child(2){
    z-index: 0;
}

.freeze-header .MuiTableHead-root th:nth-child(3),
.freeze-cell .MuiTableCell-root:nth-child(3)
{
    position: sticky;
    /* left: 145px; */
    left: 159px;
    z-index: 2;
    min-width: 85px;
    /* padding-left : 50px; */
}

.assign-per-freeze-header .MuiTableHead-root th:nth-child(3),
.assign-per-freeze-cell .MuiTableCell-root:nth-child(3){
    position: sticky;
    left: 159px;
    z-index: 2;
    min-width: 100px;
}

.freeze-cell .MuiTableCell-root:nth-child(3),
.assign-per-freeze-cell .MuiTableCell-root:nth-child(3){
    z-index: 0;
}

.freeze-header .MuiTableHead-root th:nth-child(4),
.freeze-cell .MuiTableCell-root:nth-child(4){
    position: sticky;
    /* left: 222px; */
    /* left: 248px; */
    left: 244px;
    z-index: 1;
    /* padding-left : 50px; */
}

.freeze-cell .MuiTableCell-root:nth-child(4){
    z-index: 0;
} 

/* freeze fourth columns of assignmet person list */
.assign-per-freeze-header .MuiTableHead-root th:nth-child(4),
.assign-per-freeze-cell .MuiTableCell-root:nth-child(4){
    position: sticky;
    left: 259px;
    z-index: 1;
    /* padding-left : 50px; */
}

.assign-per-freeze-cell .MuiTableCell-root:nth-child(4){
    z-index: 0;
} 

.frozen-columns
  .MuiTableRow-root:nth-child(even)
  .MuiTableCell-root:nth-child(-n + 4) {
  background-color: #ffffff; /* White background color for even rows in frozen columns */
}

.frozen-columns
  .MuiTableRow-root:nth-child(odd)
  .MuiTableCell-root:nth-child(-n + 4) {
  background-color: #f1f1f1; /* Light gray background color for odd rows in frozen columns */
}

.freeze-header .MuiTableHead-root th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)),
.assign-per-freeze-header .MuiTableHead-root th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)){
    z-index: 0 !important;
}

.freeze-cell .MuiTableCell-root:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)),
.assign-per-freeze-cell .MuiTableCell-root:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)){
    z-index: 0;
}

/* .border-datatable th,
.border-datatable td {
  border-right: 1px solid #ffffff;
  padding: 8px;
} */
/* .assign-approve-freeze-cell .MuiTableCell-root:nth-child(1){
    z-index: 0 !important;
} */

.assign-approve-freeze-header .MuiTableCell-root:nth-child(2),
.assign-approve-freeze-cell .MuiTableCell-root:nth-child(2){
    position: sticky !important;
    left: 46px;
    /* z-index: 5; */
    min-width: 113px;
}

/* .assign-approve-freeze-cell .MuiTableCell-root:nth-child(2){
    position: sticky;
    z-index: 0;
} */

/* .assign-approve-freeze-cell .MuiTableCell-root:nth-child(2){
    z-index: 2;
} */

.assign-approve-freeze-header .MuiTableHead-root th:nth-child(3),
.assign-approve-freeze-cell .MuiTableCell-root:nth-child(3)
{
    position: sticky !important;
    left: 159px;
    z-index: 4;
    min-width: 113px;
}

.assign-approve-freeze-cell .MuiTableCell-root:nth-child(3){
    z-index: 0;
}

.assign-approve-freeze-header .MuiTableHead-root th:nth-child(4),
.assign-approve-freeze-cell .MuiTableCell-root:nth-child(4){
    position: sticky;
    left: 272px;
    z-index: 3;
    min-width: 113px;
    /* padding-left : 50px; */
}

.assign-approve-freeze-cell .MuiTableCell-root:nth-child(4){
    z-index: 0;
}

.assign-approve-freeze-header .MuiTableHead-root th:nth-child(5),
.assign-approve-freeze-cell .MuiTableCell-root:nth-child(5){
    position: sticky;
    left: 385px;
    z-index: 2;
    min-width: 120px;
    /* padding-left : 50px; */
}

.assign-approve-freeze-cell .MuiTableCell-root:nth-child(5){
    z-index: 1;
}

.assign-approve-freeze-header .MuiTableHead-root th:nth-child(6),
.assign-approve-freeze-cell .MuiTableCell-root:nth-child(6){
    position: sticky;
    left: 505px;
    z-index: 1;
    min-width: 80px;
    /* padding-left : 50px; */
}

.assign-approve-freeze-cell .MuiTableCell-root:nth-child(6){
    z-index: 0;
}

.assign-approve-frozen-columns
  .MuiTableRow-root:nth-child(even)
  .MuiTableCell-root:nth-child(-n + 6) ,
  .sale-performance-frozen-columns
  .MuiTableRow-root:nth-child(even)
  .MuiTableCell-root:nth-child(-n + 6),
  .prospect-frozen-columns
  .MuiTableRow-root:nth-child(even)
  .MuiTableCell-root:nth-child(-n + 6),
  .partner-freeze-columns
  .MuiTableRow-root:nth-child(even)
  .MuiTableCell-root:nth-child(-n + 5),
  .hr-list-frozen-columns
  .MuiTableRow-root:nth-child(even)
  .MuiTableCell-root:nth-child(-n + 5),
  .purchase-order-frozen-columns
  .MuiTableRow-root:nth-child(even)
  .MuiTableCell-root:nth-child(-n + 5),
  .bill-frozen-columns
  .MuiTableRow-root:nth-child(even)
  .MuiTableCell-root:nth-child(-n + 3),
  .hr-performance-frozen-columns
  .MuiTableRow-root:nth-child(even)
  .MuiTableCell-root:nth-child(-n + 5),
  .freeze-salery-frozen-columns
  .MuiTableRow-root:nth-child(even)
  .MuiTableCell-root:nth-child(-n + 7),
  .freeze-salery-detail-frozen-columns
  .MuiTableRow-root:nth-child(even)
  .MuiTableCell-root:nth-child(-n + 7),
  .reminder-frozen-columns
  .MuiTableRow-root:nth-child(even)
  .MuiTableCell-root:nth-child(-n + 5),
  .company-info-frozen-columns
  .MuiTableRow-root:nth-child(even)
  .MuiTableCell-root:nth-child(-n + 4)
  {
  background-color: #ffffff; /* White background color for even rows in frozen columns */
}

.assign-approve-frozen-columns
  .MuiTableRow-root:nth-child(odd)
  .MuiTableCell-root:nth-child(-n + 6),
  .sale-performance-frozen-columns
  .MuiTableRow-root:nth-child(odd)
  .MuiTableCell-root:nth-child(-n + 6),
  .prospect-frozen-columns
  .MuiTableRow-root:nth-child(odd)
  .MuiTableCell-root:nth-child(-n + 6),
  .partner-freeze-columns
  .MuiTableRow-root:nth-child(odd)
  .MuiTableCell-root:nth-child(-n + 5),
  .hr-list-frozen-columns
  .MuiTableRow-root:nth-child(odd)
  .MuiTableCell-root:nth-child(-n + 3),
  .purchase-order-frozen-columns
  .MuiTableRow-root:nth-child(odd)
  .MuiTableCell-root:nth-child(-n + 3),
  .bill-frozen-columns
  .MuiTableRow-root:nth-child(odd)
  .MuiTableCell-root:nth-child(-n + 3),
  .hr-performance-frozen-columns
  .MuiTableRow-root:nth-child(odd)
  .MuiTableCell-root:nth-child(-n + 5),
  .freeze-salery-frozen-columns
  .MuiTableRow-root:nth-child(odd)
  .MuiTableCell-root:nth-child(-n + 7),
  .freeze-salery-detail-frozen-columns
  .MuiTableRow-root:nth-child(odd)
  .MuiTableCell-root:nth-child(-n + 7),
  .reminder-frozen-columns
  .MuiTableRow-root:nth-child(odd)
  .MuiTableCell-root:nth-child(-n + 5),
  .company-info-frozen-columns
  .MuiTableRow-root:nth-child(odd)
  .MuiTableCell-root:nth-child(-n + 4)
  {
  background-color: #f1f1f1; /* Light gray background color for odd rows in frozen columns */
}

.assign-approve-freeze-header .MuiTableHead-root th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5)):not(:nth-child(6)),
.procedure-freeze-header .MuiTableHead-root th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5)),
.assign-case-freeze-header .MuiTableHead-root th:not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5)),
.prospect-freeze-header .MuiTableHead-root th:not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5)):not(:nth-child(6)),
.partner-freeze-header .MuiTableHead-root th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5)),
.hr-list-freeze-header .MuiTableHead-root th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)),
.purchase-order-freeze-header .MuiTableHead-root th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)),
.bill-freeze-header .MuiTableHead-root th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)),
.freeze-salery-header .MuiTableHead-root th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5)):not(:nth-child(6)):not(:nth-child(7)),
.freeze-salery-detail-header .MuiTableHead-root th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5)):not(:nth-child(6)):not(:nth-child(7)),
.freeze-reminder-header .MuiTableHead-root th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5)),
.freeze-company-info-header .MuiTableHead-root th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4))
{
    z-index: 0;
}

.assign-approve-freeze-cell .MuiTableCell-root:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5)):not(:nth-child(6)),
.procedure-freeze-cell .MuiTableCell-root th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5)),
.partner-freeze-cell .MuiTableCell-root th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5)),
.hr-list-freeze-cell .MuiTableCell-root th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)),
.purchase-order-freeze-cell .MuiTableCell-root th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)),
.bill-freeze-cell .MuiTableCell-root th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)),
.freeze-salery-cell .MuiTableCell-root th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5)):not(:nth-child(6)):not(:nth-child(7)),
.freeze-salery-cell .MuiTableCell-root th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5)):not(:nth-child(6)):not(:nth-child(7)),
.freeze-reminder-cell .MuiTableCell-root th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5)),
.freeze-company-info-cell .MuiTableCell-root th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4))
{
    z-index: 0 !important;
}

/* Proceducre List Freeze Columns */
.procedure-freeze-header .MuiTableHead-root th:nth-child(1),
.procedure-freeze-cell.MuiTableCell-root:nth-child(1){
    position: sticky;
    left: 2;
    min-width: 46px;
    /* z-index: 4; */
    /* margin-left:50px; */
}

/* .procedure-freeze-cell .MuiTableCell-root:nth-child(1){
    z-index: 1 !important;
} */

.procedure-freeze-header .MuiTableCell-root:nth-child(2),
.procedure-freeze-cell .MuiTableCell-root:nth-child(2){
    position: sticky !important;
    left: 46px;
    /* z-index: 5; */
    min-width: 113px;
}

/* .procedure-freeze-cell .MuiTableCell-root:nth-child(2){
    z-index: 2;
} */

.procedure-freeze-header .MuiTableHead-root th:nth-child(3),
.procedure-freeze-cell .MuiTableCell-root:nth-child(3)
{
    position: sticky !important;
    left: 159px;
    z-index: 4;
    min-width: 90px;
    /* min-width: 113px; */
}

.procedure-freeze-cell .MuiTableCell-root:nth-child(3){
    z-index: 0;
}

.procedure-freeze-header .MuiTableHead-root th:nth-child(4),
.procedure-freeze-cell .MuiTableCell-root:nth-child(4){
    position: sticky;
    left: 249px;
    z-index: 3;
    min-width: 90px;
    /* padding-left : 50px; */
}

.procedure-freeze-cell .MuiTableCell-root:nth-child(4){
    z-index: 0;
}

.procedure-freeze-header .MuiTableHead-root th:nth-child(5),
.procedure-freeze-cell .MuiTableCell-root:nth-child(5){
    position: sticky;
    left: 339px;
    z-index: 2;
    /* padding-left : 50px; */
}

.procedure-freeze-cell .MuiTableCell-root:nth-child(5){
    z-index: 0;
}

.procedure-frozen-columns
.MuiTableRow-root:nth-child(even)
.MuiTableCell-root:nth-child(-n + 5),
.assign-case-frozen-columns
.MuiTableRow-root:nth-child(even)
.MuiTableCell-root:nth-child(-n + 5){
background-color: #ffffff; /* White background color for even rows in frozen columns */
}

.procedure-frozen-columns
  .MuiTableRow-root:nth-child(odd)
  .MuiTableCell-root:nth-child(-n + 5),
  .assign-case-frozen-columns
  .MuiTableRow-root:nth-child(odd)
  .MuiTableCell-root:nth-child(-n + 5) {
  background-color: #f1f1f1; /* Light gray background color for odd rows in frozen columns */
}

.tss-1fug43a-MUIDataTableBodyCell-root{
    padding: 2px !important;
}

/*freeze SaleList */
.freeze-sale-header .MuiTableHead-root th:nth-child(2),
.freeze-sale-cell .MuiTableCell-root:nth-child(2){
    position: sticky;
    left: 46px;
    z-index: 3;
    min-width: 113px;
}
.freeze-sale-cell .MuiTableCell-root:nth-child(2){
    z-index: 0;
}

.freeze-sale-header .MuiTableHead-root th:nth-child(3),
.freeze-sale-cell .MuiTableCell-root:nth-child(3)
{
    position: sticky !important;
    left: 159px;
    z-index: 2;
    min-width: 113px;
}

.freeze-sale-cell .MuiTableCell-root:nth-child(3){
    z-index: 0;
}
.freeze-sale-header .MuiTableHead-root th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)){
    z-index: 0;
}
/* .freeze-sale-header th span{
    height: 30px !important;
}
.freeze-sale-header th span div div{
    padding: 5px !important;
} */

/*freeze SalePerformanceList */
.freeze-sale-performance-header .MuiTableHead-root th:nth-child(1),
.freeze-sale-performance-cell .MuiTableCell-root:nth-child(1),
.freeze-hr-performance-header .MuiTableHead-root th:nth-child(1),
.freeze-hr-performance-cell .MuiTableCell-root:nth-child(1){
    position: sticky !important;
    left: 0;
    z-index: 6;
    min-width: 46px;
}

.freeze-sale-performance-cell .MuiTableCell-root:nth-child(1),
.freeze-hr-performance-cell .MuiTableCell-root:nth-child(1)
{
    z-index: 0;
}

.freeze-sale-performance-header .MuiTableCell-root:nth-child(2),
.freeze-sale-performance-cell .MuiTableCell-root:nth-child(2),
.freeze-hr-performance-header .MuiTableCell-root:nth-child(2),
.freeze-hr-performance-cell .MuiTableCell-root:nth-child(2){
    position: sticky !important;
    left: 46px;
    min-width: 120px;
    /* z-index: 5 !important; */
}

/* .freeze-sale-performance-cell .MuiTableCell-root:nth-child(2){
    z-index: 0 ;
} */

.freeze-sale-performance-header .MuiTableHead-root th:nth-child(3),
.freeze-sale-performance-cell .MuiTableCell-root:nth-child(3),
.freeze-hr-performance-header .MuiTableHead-root th:nth-child(3),
.freeze-hr-performance-cell .MuiTableCell-root:nth-child(3)
{
    position: sticky !important;
    left: 166px;
    z-index: 4;
    min-width: 123px;
}

.freeze-sale-performance-cell .MuiTableCell-root:nth-child(3),
.freeze-hr-performance-cell .MuiTableCell-root:nth-child(3)
{
    z-index: 0 ;
}

.freeze-sale-performance-header .MuiTableHead-root th:nth-child(4),
.freeze-sale-performance-cell .MuiTableCell-root:nth-child(4),
.freeze-hr-performance-header .MuiTableHead-root th:nth-child(4),
.freeze-hr-performance-cell .MuiTableCell-root:nth-child(4){
    position: sticky;
    left: 289px;
    z-index: 3;
    min-width: 113px;
    /* padding-left : 50px; */
}

.freeze-sale-performance-cell .MuiTableCell-root:nth-child(4),
.freeze-hr-performance-cell .MuiTableCell-root:nth-child(4){
    z-index: 0 ;
}

.freeze-sale-performance-header .MuiTableHead-root th:nth-child(5),
.freeze-sale-performance-cell .MuiTableCell-root:nth-child(5),
.freeze-hr-performance-header .MuiTableHead-root th:nth-child(5),
.freeze-hr-performance-cell .MuiTableCell-root:nth-child(5){
    position: sticky;
    left: 402px;
    z-index: 2;
    /* padding-left : 50px; */
}

.freeze-sale-performance-cell .MuiTableCell-root:nth-child(5),
.freeze-hr-performance-cell .MuiTableCell-root:nth-child(5){
    z-index: 0 ;
}

.freeze-sale-performance-cell .MuiTableHead-root th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5)),
.freeze-hr-performance-cell .MuiTableHead-root th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5)){
    z-index: 0 ;
}

/*freeze setting User List */

.user-freeze-header .MuiTableHead-root th:nth-child(1),
.user-freeze-cell .MuiTableCell-root:nth-child(1){
    position: sticky;
    left: 2;
    z-index: 5;
    min-width: 46px;
    /* margin-left:50px; */
}

.user-freeze-cell .MuiTableCell-root:nth-child(1){
    z-index: 0;
}

.user-freeze-header .MuiTableCell-root:nth-child(2),
.user-freeze-cell .MuiTableCell-root:nth-child(2){
    position: sticky !important;
    left: 46px;
    /* z-index: 4 !important; */
    min-width: 113px;
}

/* .user-freeze-cell .MuiTableCell-root:nth-child(2){
    z-index: 1 !important;
} */

.user-freeze-header .MuiTableHead-root th:nth-child(3),
.user-freeze-cell .MuiTableCell-root:nth-child(3)
{
    position: sticky !important;
    left: 159px;
    z-index: 3;
    min-width: 113px;
}

.user-freeze-cell .MuiTableCell-root:nth-child(3){
    z-index: 0;
}

.user-freeze-header .MuiTableHead-root th:nth-child(4),
.user-freeze-cell .MuiTableCell-root:nth-child(4){
    position: sticky;
    left: 272px;
    z-index: 2;
    min-width: 113px;
    /* padding-left : 50px; */
}

.user-freeze-cell .MuiTableCell-root:nth-child(4){
    z-index: 0;
}

.user-frozen-columns .MuiTableHead-root th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)){
    z-index: 0;
}
.user-frozen-columns
.MuiTableRow-root:nth-child(even)
.MuiTableCell-root:nth-child(-n + 4) {
background-color: #ffffff; /* White background color for even rows in frozen columns */
}

.user-frozen-columns
  .MuiTableRow-root:nth-child(odd)
  .MuiTableCell-root:nth-child(-n + 4){
  background-color: #f1f1f1; /* Light gray background color for odd rows in frozen columns */
}

/*freeze AssignmentListCase */
.assign-case-freeze-header .MuiTableHead-root th:nth-child(1),
.assign-case-freeze-cell .MuiTableCell-root:nth-child(1){
    position: sticky !important;
    left: 0;
    z-index: 5 !important;
}

.assign-case-freeze-cell .MuiTableCell-root:nth-child(1){
    z-index: 0 !important;
}

.assign-case-freeze-header .MuiTableCell-root:nth-child(2){
    position: sticky !important;
    left: 46px;
    /* z-index: 4 !important; */
    min-width: 113px;
}

/* .assign-case-freeze-cell .MuiTableCell-root:nth-child(2){
    z-index: 0;
} */

.assign-case-freeze-header .MuiTableHead-root th:nth-child(3),
.assign-case-freeze-cell .MuiTableCell-root:nth-child(3)
{
    position: sticky !important;
    left: 159px;
    z-index: 3;
    min-width: 113px;
}

.assign-case-freeze-cell .MuiTableCell-root:nth-child(3){
    z-index: 0 ;
}

.assign-case-freeze-header .MuiTableHead-root th:nth-child(4),
.assign-case-freeze-cell .MuiTableCell-root:nth-child(4){
    position: sticky;
    left: 272px;
    z-index: 2;
    min-width: 100px;
}

.assign-case-freeze-cell .MuiTableCell-root:nth-child(4){
    z-index: 0 ;
}

.assign-case-freeze-header .MuiTableHead-root th:nth-child(5),
.assign-case-freeze-cell .MuiTableCell-root:nth-child(5){
    position: sticky !important;
    /* left: 358px; */
    left: 372px;
    z-index: 1;
}

.assign-case-freeze-cell .MuiTableCell-root:nth-child(5){
    z-index: 0 ;
}

.assign-case-freeze-cell .MuiTableHead-root th:not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5)){
    z-index: 0;
}

/* .assign-case-freeze-header .MuiTableHead-root th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5))
.assign-case-freeze-cell .MuiTableHead-root th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5)){
    z-index: 0;
} */

/*freeze ProspectList */
.prospect-freeze-header .MuiTableHead-root th:nth-child(1),
.prospect-freeze-cell .MuiTableCell-root:nth-child(1){
    position: sticky !important;
    left: 0;
    z-index: 7 !important;
    min-width: 46px;
}

.prospect-freeze-cell .MuiTableCell-root:nth-child(1){
    z-index: 0 !important;
}

.prospect-freeze-header .MuiTableCell-root:nth-child(2){
    position: sticky !important;
    left: 46px;
    z-index: 6 !important;
    min-width: 113px;
}

.prospect-freeze-cell .MuiTableCell-root:nth-child(2){
    z-index: 0;
}

.prospect-freeze-header .MuiTableHead-root th:nth-child(3),
.prospect-freeze-cell .MuiTableCell-root:nth-child(3)
{
    position: sticky !important;
    left: 159px;
    z-index: 5;
    min-width: 113px;
}

.prospect-freeze-cell .MuiTableCell-root:nth-child(3){
    z-index: 0 ;
}

.prospect-freeze-header .MuiTableHead-root th:nth-child(4),
.prospect-freeze-cell .MuiTableCell-root:nth-child(4){
    position: sticky;
    left: 272px;
    z-index: 4;
    min-width: 113px;
}

.prospect-freeze-cell .MuiTableCell-root:nth-child(4){
    z-index: 0 ;
}

.prospect-freeze-header .MuiTableHead-root th:nth-child(5),
.prospect-freeze-cell .MuiTableCell-root:nth-child(5){
    position: sticky !important;
    left: 385px;
    z-index: 3;
    min-width: 113px;
}

.prospect-freeze-cell .MuiTableCell-root:nth-child(5){
    z-index: 0 ;
}

.prospect-freeze-header .MuiTableHead-root th:nth-child(6),
.prospect-freeze-cell .MuiTableCell-root:nth-child(6){
    position: sticky !important;
    left: 498px;
    z-index: 2;
}

.prospect-freeze-cell .MuiTableCell-root:nth-child(6){
    z-index: 0 ;
}

/*freeze PartnerList */
.partner-freeze-header .MuiTableHead-root th:nth-child(1),
.partner-freeze-cell .MuiTableCell-root:nth-child(1){
    position: sticky !important;
    left: 0;
    z-index: 6 !important;
    min-width: 46px;
}

.partner-freeze-cell .MuiTableCell-root:nth-child(1){
    z-index: 0 !important;
}

.partner-freeze-header .MuiTableCell-root:nth-child(2){
    position: sticky !important;
    left: 46px;
    z-index: 5 !important;
    min-width: 110px;
}

.prospect-freeze-cell .MuiTableCell-root:nth-child(2){
    z-index: 0;
}

.partner-freeze-header .MuiTableHead-root th:nth-child(3),
.partner-freeze-cell .MuiTableCell-root:nth-child(3)
{
    position: sticky !important;
    left: 156px;
    z-index: 4;
    min-width: 113px;
}

.partner-freeze-cell .MuiTableCell-root:nth-child(3){
    z-index: 0 ;
}

.partner-freeze-header .MuiTableHead-root th:nth-child(4),
.partner-freeze-cell .MuiTableCell-root:nth-child(4){
    position: sticky;
    left: 269px;
    z-index: 3;
    min-width: 113px;
}

.partner-freeze-cell .MuiTableCell-root:nth-child(4){
    z-index: 0 ;
}

.partner-freeze-header .MuiTableHead-root th:nth-child(5),
.partner-freeze-cell .MuiTableCell-root:nth-child(5){
    position: sticky !important;
    left: 382px;
    z-index: 2;
}

.partner-freeze-cell .MuiTableCell-root:nth-child(5){
    z-index: 0 ;
}

/*freeze HRList */
.hr-list-freeze-header .MuiTableHead-root th:nth-child(1),
.hr-list-freeze-cell .MuiTableCell-root:nth-child(1){
    position: sticky !important;
    left: 0;
    z-index: 6 !important;
    min-width: 46px;
}

.hr-list-freeze-cell .MuiTableCell-root:nth-child(1){
    z-index: 0 !important;
}

.hr-list-freeze-header .MuiTableCell-root:nth-child(2){
    position: sticky !important;
    left: 46px;
    z-index: 5 !important;
    min-width: 110px;
}

.hr-list-freeze-cell .MuiTableCell-root:nth-child(2){
    z-index: 0;
}

.hr-list-freeze-header .MuiTableHead-root th:nth-child(3),
.hr-list-freeze-cell .MuiTableCell-root:nth-child(3)
{
    position: sticky !important;
    left: 156px;
    z-index: 4;
    min-width: 113px;
}

.hr-list-freeze-cell .MuiTableCell-root:nth-child(3){
    z-index: 0 ;
}

/*freeze Purchase Order List */
.purchase-order-freeze-header .MuiTableHead-root th:nth-child(1),
.purchase-order-freeze-cell .MuiTableCell-root:nth-child(1){
    position: sticky !important;
    left: 0;
    z-index: 6 !important;
}

.purchase-order-freeze-cell .MuiTableCell-root:nth-child(1){
    z-index: 0 !important;
}

.purchase-order-freeze-header .MuiTableCell-root:nth-child(2){
    position: sticky !important;
    left: 34px;
    z-index: 5 !important;
    min-width: 110px;
}

.purchase-order-freeze-cell .MuiTableCell-root:nth-child(2){
    z-index: 0;
}

.purchase-order-freeze-header .MuiTableHead-root th:nth-child(3),
.purchase-order-freeze-cell .MuiTableCell-root:nth-child(3)
{
    position: sticky !important;
    left: 144px;
    z-index: 4;
    min-width: 113px;
}

.purchase-order-freeze-cell .MuiTableCell-root:nth-child(3){
    z-index: 0 ;
}

/*freeze purchase Order List */
.purchase-order-freeze-header .MuiTableHead-root th:nth-child(1),
.purchase-order-freeze-cell .MuiTableCell-root:nth-child(1){
    position: sticky !important;
    left: 0;
    z-index: 6 !important;
    min-width: 46px;
}

.purchase-order-freeze-cell .MuiTableCell-root:nth-child(1){
    z-index: 0 !important;
}

.purchase-order-freeze-header .MuiTableCell-root:nth-child(2){
    position: sticky !important;
    left: 46px;
    z-index: 5 !important;
    min-width: 110px;
}

.purchase-order-freeze-cell .MuiTableCell-root:nth-child(2){
    z-index: 0;
}

.purchase-order-freeze-header .MuiTableHead-root th:nth-child(3),
.purchase-order-freeze-cell .MuiTableCell-root:nth-child(3)
{
    position: sticky !important;
    left: 156px;
    z-index: 4;
    min-width: 113px;
}

.purchase-order-freeze-cell .MuiTableCell-root:nth-child(3){
    z-index: 0 ;
}

/*freeze Bill Order List */
.bill-freeze-header .MuiTableHead-root th:nth-child(1),
.bill-freeze-cell .MuiTableCell-root:nth-child(1){
    position: sticky !important;
    left: 0;
    z-index: 6 !important;
    min-width: 46px;
}

.bill-freeze-cell .MuiTableCell-root:nth-child(1){
    z-index: 0 !important;
}

.bill-freeze-header .MuiTableCell-root:nth-child(2){
    position: sticky !important;
    left: 46px;
    z-index: 5 !important;
    min-width: 110px;
}

.bill-freeze-cell .MuiTableCell-root:nth-child(2){
    z-index: 0;
}

.bill-freeze-header .MuiTableHead-root th:nth-child(3),
.bill-freeze-cell .MuiTableCell-root:nth-child(3)
{
    position: sticky !important;
    left: 156px;
    z-index: 4;
    min-width: 113px;
}

.bill-freeze-cell .MuiTableCell-root:nth-child(3){
    z-index: 0 ;
}

/*freeze SaleryList */
.freeze-salery-header .MuiTableHead-root th:nth-child(1),
.freeze-salery-cell .MuiTableCell-root:nth-child(1){
    position: sticky !important;
    left: 0;
    z-index: 8 !important;
    min-width: 46px;
}

.freeze-salery-cell .MuiTableCell-root:nth-child(1){
    z-index: 0 !important;
}

.freeze-salery-header .MuiTableCell-root:nth-child(2){
    position: sticky !important;
    left: 46px;
    /* z-index: 7 !important; */
    min-width: 113px;
}

/* .freeze-salery-cell .MuiTableCell-root:nth-child(2){
    z-index: 0;
} */

.freeze-salery-header .MuiTableHead-root th:nth-child(3),
.freeze-salery-cell .MuiTableCell-root:nth-child(3)
{
    position: sticky !important;
    left: 159px;
    z-index: 6;
    min-width: 113px;
}

.freeze-salery-cell .MuiTableCell-root:nth-child(3){
    z-index: 0 ;
}

.freeze-salery-header .MuiTableHead-root th:nth-child(4),
.freeze-salery-cell .MuiTableCell-root:nth-child(4)
{
    position: sticky !important;
    left: 272px;
    z-index: 5;
    min-width: 113px;
}

.freeze-salery-cell .MuiTableCell-root:nth-child(4){
    z-index: 0 ;
}

.freeze-salery-header .MuiTableHead-root th:nth-child(5),
.freeze-salery-cell .MuiTableCell-root:nth-child(5)
{
    position: sticky !important;
    left: 385px;
    z-index: 4;
    min-width: 113px;
}

.freeze-salery-cell .MuiTableCell-root:nth-child(5){
    z-index: 0 ;
}

.freeze-salery-header .MuiTableHead-root th:nth-child(6),
.freeze-salery-cell .MuiTableCell-root:nth-child(6)
{
    position: sticky !important;
    left: 498px;
    z-index: 3;
    min-width: 120px;
}

.freeze-salery-cell .MuiTableCell-root:nth-child(6){
    z-index: 0 ;
}

.freeze-salery-header .MuiTableHead-root th:nth-child(7),
.freeze-salery-cell .MuiTableCell-root:nth-child(7)
{
    position: sticky !important;
    left: 618px;
    z-index: 2;
    min-width: 113px;
}

.freeze-salery-cell .MuiTableCell-root:nth-child(7){
    z-index: 0 ;
}

/*freeze SaleryDetailList */
.freeze-salery-detail-header .MuiTableHead-root th:nth-child(1),
.freeze-salery-detail-cell .MuiTableCell-root:nth-child(1){
    position: sticky !important;
    left: 0;
    z-index: 8 !important;
    min-width: 46px;
}

.freeze-salery-detail-cell .MuiTableCell-root:nth-child(1){
    z-index: 0 !important;
}

.freeze-salery-detail-header .MuiTableCell-root:nth-child(2){
    position: sticky !important;
    left: 46px;
    /* z-index: 7 !important; */
    min-width: 110px;
}

/* .freeze-salery-detail-cell .MuiTableCell-root:nth-child(2){
    z-index: 0;
} */

.freeze-salery-detail-header .MuiTableHead-root th:nth-child(3),
.freeze-salery-detail-cell .MuiTableCell-root:nth-child(3)
{
    position: sticky !important;
    left: 156px;
    z-index: 6;
    min-width: 113px;
}

.freeze-salery-detail-cell .MuiTableCell-root:nth-child(3){
    z-index: 0 ;
}

.freeze-salery-detail-header .MuiTableHead-root th:nth-child(4),
.freeze-salery-detail-cell .MuiTableCell-root:nth-child(4)
{
    position: sticky !important;
    left: 269px;
    z-index: 5;
    min-width: 131.7px;
}

.freeze-salery-detail-cell .MuiTableCell-root:nth-child(4){
    z-index: 0 ;
}

.freeze-salery-detail-header .MuiTableHead-root th:nth-child(5),
.freeze-salery-detail-cell .MuiTableCell-root:nth-child(5)
{
    position: sticky !important;
    left: 401px;
    z-index: 4;
    min-width: 113px;
}

.freeze-salery-detail-cell .MuiTableCell-root:nth-child(5){
    z-index: 0 ;
}

.freeze-salery-detail-header .MuiTableHead-root th:nth-child(6),
.freeze-salery-detail-cell .MuiTableCell-root:nth-child(6)
{
    position: sticky !important;
    left: 514px;
    z-index: 3;
    min-width: 113px;
}

.freeze-salery-detail-cell .MuiTableCell-root:nth-child(6){
    z-index: 0 ;
}

.freeze-salery-detail-header .MuiTableHead-root th:nth-child(7),
.freeze-salery-detail-cell .MuiTableCell-root:nth-child(7)
{
    position: sticky !important;
    left: 627px;
    z-index: 2;
    min-width: 113px;
}

.freeze-salery-detail-cell .MuiTableCell-root:nth-child(7){
    z-index: 0 ;
}

/*freeze ReminderList */
.freeze-reminder-header .MuiTableHead-root th:nth-child(1),
.freeze-reminder-cell .MuiTableCell-root:nth-child(1){
    position: sticky !important;
    left: 0;
    z-index: 6 !important;
    min-width: 46px;
}

.freeze-reminder-cell .MuiTableCell-root:nth-child(1){
    z-index: 0 !important;
}

.freeze-reminder-header .MuiTableCell-root:nth-child(2){
    position: sticky !important;
    left: 46px;
    /* z-index: 7 !important; */
    min-width: 113px;
}

/* .freeze-salery-detail-cell .MuiTableCell-root:nth-child(2){
    z-index: 0;
} */

.freeze-reminder-header .MuiTableHead-root th:nth-child(3),
.freeze-reminder-cell .MuiTableCell-root:nth-child(3)
{
    position: sticky !important;
    left: 159px;
    z-index: 5;
    min-width: 113px;
}

.freeze-reminder-cell .MuiTableCell-root:nth-child(3){
    z-index: 0 ;
}

.freeze-reminder-header .MuiTableHead-root th:nth-child(4),
.freeze-reminder-cell .MuiTableCell-root:nth-child(4)
{
    position: sticky !important;
    left: 272px;
    z-index: 4;
    min-width: 113px;
}

.freeze-reminder-cell .MuiTableCell-root:nth-child(4){
    z-index: 0 ;
}

.freeze-reminder-header .MuiTableHead-root th:nth-child(5),
.freeze-reminder-cell .MuiTableCell-root:nth-child(5)
{
    position: sticky !important;
    left: 385px;
    z-index: 3;
    min-width: 113px;
}

.freeze-reminder-cell .MuiTableCell-root:nth-child(5){
    z-index: 0 ;
}

/*freeze CompanyInfoList */
.freeze-company-info-header .MuiTableHead-root th:nth-child(1),
.freeze-company-info-cell .MuiTableCell-root:nth-child(1){
    position: sticky !important;
    left: 0;
    z-index: 6 !important;
}

.freeze-company-info-cell .MuiTableCell-root:nth-child(1){
    z-index: 0 !important;
}

.freeze-company-info-header .MuiTableCell-root:nth-child(2){
    position: sticky !important;
    left: 34px;
    /* z-index: 7 !important; */
    min-width: 110px;
}

.freeze-company-info-header .MuiTableHead-root th:nth-child(3),
.freeze-company-info-cell .MuiTableCell-root:nth-child(3)
{
    position: sticky !important;
    left: 144px;
    z-index: 5;
    min-width: 113px;
}

.freeze-company-info-cell .MuiTableCell-root:nth-child(3){
    z-index: 0 ;
}

.freeze-company-info-header .MuiTableHead-root th:nth-child(4),
.freeze-company-info-cell .MuiTableCell-root:nth-child(4)
{
    position: sticky !important;
    left: 255px;
    z-index: 4;
    min-width: 113px;
}

.freeze-company-info-cell .MuiTableCell-root:nth-child(4){
    z-index: 0 ;
}



.case_list .MuiTableHead-root th:nth-child(3), .work_list_datatable .MuiTableCell-root:nth-child(3){
    min-width: 150px !important;
}
.case_list .MuiTableHead-root th:nth-child(4), 
.case_list .MuiTableCell-root:nth-child(4){
    left: 209px !important;
}

/* .case_list .MuiTableHead-root th:nth-child(4), .case_list .MuiTableCell-root:nth-child(4){
    position: unset !important;
} */

.person_list .MuiTableHead-root th:nth-child(2), .person_list .MuiTableCell-root:nth-child(2) {
    min-width: 80px !important;
}
.person_list .MuiTableHead-root th:nth-child(3), .person_list .MuiTableCell-root:nth-child(3){
    left: 126px;
    min-width: 120px;
}
.person_list .MuiTableHead-root th:nth-child(4), .person_list .MuiTableCell-root:nth-child(4){
    /* left: 246px; */
    min-width: 113px;
}



/* .datatable table th, .datatable table td{
    border: 1px solid grey;
} */
.react-datepicker__tab-loop{
    display: inline-block !important;
}


.datatable table {
    width: 100%;
    text-align: center;
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
  }
  
.datatable table th {
/* Apply both top and bottom borders to the <th> */
/* border-top: 1px solid grey; */
/* border-bottom: 1px solid grey; */
/* border-right: 1px solid grey; */
 /* border: 1px solid grey;  */
 border-right: 1px;
 /* border-bottom: 0.5px; */
 border-top: 0.5px;
 border-style: solid; 
 border-color: grey;
}

.datatable table td {
    border-right: 0.5px;
    /* border-bottom: 0.5px; */
    /* border-top: 0.5px; */
    border-style: solid; 
    border-color: grey;
    /* For cells, apply the border to one of each side only (right but not left, bottom but not top) */
    /* border-bottom: 1px solid grey;
    border-right: 1px solid grey; */
  }

  .css-1ygcj2i-MuiTableCell-root{
    padding: 0px 15px 0px 15px !important;
  }
  .datatable th div,.datatable td div{
    max-height: 30px !important;
  }
  .css-1ex1afd-MuiTableCell-root{
    line-height: 1.11 !important;
  }
  .user_form .react-datepicker-wrapper{
    /* float: right !important; */
  }
  .user_form .react-datepicker-wrapper input[type=text],
  .user_form .react-datepicker-wrapper input[type=text]:focus, 
  .user_form .react-datepicker-wrapper input[type=text]:active, 
  .user_form .react-datepicker-wrapper input[type=text]:hover,
  .user_form .react-datepicker-wrapper input[type=text]:focus-visible{
    padding: 5px 15px !important;
    /* margin-left: 4px; */
    /* width: 95%; */
    margin-bottom: 10px;
  }

.mt_4{
    margin-top: 3.5rem !important;
}
.pr-15{
    padding-right: 15px !important;
}
.hidden{
    display: none !important;
}
.detail .profile .status_btn_1 {
    position: absolute;
    border-radius: 22px;
    top: 240px;
    font-size: 8px;
    left: 310px;
    color: #fff !important;
    padding: 5px 20px;
    border-radius: 10px;
    background-color: red !important;
    border: 1px solid rgb(249, 249, 249) !important;
}
.detail .profile .status_btn_2 {
    position: absolute;
    border-radius: 22px;
    top: 240px;
    font-size: 11px;
    /* margin-left: 3em;
    margin-top: 1em; */
    left: 325px;
    color: #fff !important;
    padding: 5px 20px;
    border-radius: 10px;
    background-color: rgb(37,157,99) !important;
    border: 1px solid rgb(37,157,99) !important;
}
.detail span  a.btn_standard {
    /* padding-left: 15px;
    padding-right: 15px; */
    margin-left: 2px;
    padding: 6px 5px;
    /* padding-top: 3px !important;
    padding-bottom: 3px !important; */
}
.edit-btn{
    float: right;
    background-color: #b79766;
    border: 1px solid #b79766 !important;
    padding: 2px 5px;
    border-radius: 10px;
    outline: 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff !important;
    margin-left: 1em;
    width: 70px;
    text-align: center;
    margin-top: 3px;
    text-decoration: none;
}
.inline span{
    display: inline-block;
}
.status-menu li{
    border: 1px solid rgb(0,23,193);
    border-radius: 30px !important;
    margin-right: 5px !important;
}
.sub-status-menu li {
    /* background-color: #444;
    padding: 2px 10px;
    color: white;
    border: unset !important;
    border-radius: unset !important;
    margin-right: unset !important;
    border-left: 1px solid black !important;
    border-bottom: 1px solid #444 !important; */
}
.sub-status-menu::after {
    content: "";
    width: 0;
    height: 0;
    border-top: 7px solid rgb(0,23,193);
    border-left:  7px solid transparent;
    border-right: 7px solid transparent;
    position: absolute;
    left: 21px;
    bottom: 0;
    top: 29px;
  }

/* .sub-status-menu.custom {
    --after-border-top: 10px solid rgb(0,23,193);
  }

.sub-status-menu.custom::after{
    content: "";
    width: 0;
    height: 0;
    border-top: var(--after-border-top);
    border-left:  10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    left: 25px;
    bottom: 0;
    top: 26px;
} */

.sub-status-menu{
    /* top: 93px !important; */
    position: absolute;
    /* left: 380px; */
    left: 22%;
    top: 95px !important;
}
.status-menu li.data-exist {
    background-color: #b79766;
    border: 1px solid #b79766;
    color:#fff !important;
}

.status-menu li.no-data {
    background-color: rgb(0, 23, 193);
    color: white !important;
    border: 1px solid rgb(0, 23, 193);
}

.case_list a{
    font-size: 13px !important;
}
.pl-4{
    padding-left: 4px;
}

.tss-1acbflw-MUIDataTable-responsiveScrollMaxHeight {
    overflow: visible;
    height: 100%;
    overflow-x: scroll !important;
    overflow-y :scroll !important;
    /* max-height: unset !important; */
}

.datatable::-webkit-scrollbar{
    display: none; 
   }
.tss-1dqj55d-MUIDataTableToolbarSelect-root .btn_standard{
    margin-right: 15px !important;
}
.tss-1qtl85h-MUIDataTableBodyCell-root{
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}

.disable-link {
    pointer-events: none;
}

.assign-edit-btn{
    background-color: #b79766;
    border: 1px solid #b79766 !important;
    padding: 4px 15px;
    border-radius: 10px;
    outline: 0;
    font-size: 12px;
    font-weight: bold;
    color: #fff !important;
    width: 70px;
    text-align: center;
    margin-top: 3px;
    text-decoration: none;
}
/* .tss-1fbujeu-MUIDataTableHeadCell-toolButton{
    margin-left: -4px !important;
    min-width: 0;
    margin-right: 4px !important;
    padding-left: 4px !important;
    padding-right: 0px !important;
} */
.tss-1fbujeu-MUIDataTableHeadCell-toolButton {
    margin-left: 0px !important;
    min-width: 0;
    margin-right: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}
/* th .MuiSvgIcon-root{
    font-size: 10px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
} */
.mt_40{
    margin-top: -40px;
}

/* 403 */
.position-ref {
    position: relative;
}

.flex-center{
    align-items: center;
    display: flex;
    justify-content: center;
}

.full-height{
    height: 100vh;
}
.code{
    border-right: 2px solid;
    font-size: 26px;
    padding: 0 15px 0 15px;
    text-align: center;
}
.message{
    font-size: 18px;
    padding: 10px;
    text-align: center;
}
.btn_disable{
    background: #888888;
    pointer-events: none;
    border: 1px solid #888888;
    font-size: 12px;
    color: #fff !important;
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 10px;
}
.overtime_flg{
    font-size:  12px !important;
}
.fc-icon-chevron-left::before, .fc-icon-chevron-right::before{
    color: #fff !important;
}
.fc .fc-toolbar-title{
    font-size: 1.5em;
}
.fc .fc-button {
    font-size: 0.9em !important;
    padding: 0.2em 0.35em !important;
}
.user_form .react-datepicker__close-icon{
    padding: 0 10px 10px 0 !important;
}
.assignment_add label,.partner_create_form label{
    font-size: 13px !important;
    display: block;
}
.show_svg{
    width:80%;
    margin: auto !important;
}

.svg{
    width:"80%";
    margin:"auto";
    background-color:"#fff";
    margin-top:"20px";
    margin-bottom:"50px";
    border:"1px solid #cecece";
}
.datatable th{
    background-color: rgb(193, 193, 193) !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.datatable td{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.MuiButtonBase-root.MuiListItemButton-root:hover,
.MuiButtonBase-root.MuiListItemButton-root:active{
    color: #fff !important;
}

.priority_img{
    float: right;
    /* border : 1px solid grey; */
    border-radius: 50%;
    width: 100%;
}
@media (min-width: 1400px) { 
    .priority_img{
        width: 65%;
    }
}

.mobile td:nth-child(1),.mobile th:nth-child(1){
    position: unset !important;
    left: 0px;
    z-index: 0 !important;
}
.filter_select .react-datepicker-wrapper input[name=work_month]{
    font-size: 12px;
    margin-left: 2px !important;
    padding: unset !important;
    padding-left: 4px !important;

}
.action .btn_standard{
    padding: 2px 10px !important;
}
.performance_list .react-datepicker__tab-loop{
    display: block !important;
}

.attachment-img{
    width: 10%;
    border: 1px solid black;
    margin: 10px;
    float: left;
}
.show_uploaded_file a{
    display: inline-block !important;
}
.dashboard-summary-block  .text-align-unset{
    text-align: left !important;
}
.remove{
    color: #fff;
    background-color: red;
    padding: 3px;
    border-radius: 10px;
}
.remove.fa-close:before{
    font-size: 12px;
}

.sender .chat_img{
    width: 20px !important;
    height: 20px !important;
    border-radius: 50%;
}

.summary-block span{
    display: inline-block;
    margin-bottom: 10px;
}

.count{
    padding: 3px;
    font-size: 8px;
}


/* .loader-container {
    position: fixed; 
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center; 
    background: rgba(255, 255, 255, 0.8); 
    z-index: 1000; 
  } */
  .maxHeight{
    position: relative;
    max-height: 499px;
    height: auto;
  }