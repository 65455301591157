@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Ubuntu:wght@300&display=swap');

.container{
    margin: 0;
    padding: 0;
}

body {
    font-family: 'siteFont', 'Notosan', YuGothic, '游ゴシック', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', 'Meiryo', 'メイリオ', 'ＭＳ Ｐゴシック', 'ＭＳ ゴシック', Osaka, Osaka-等幅, sans-serif;
    font-size: 12px;
    line-height: 2;
}

.login-content {
    position: absolute;
    left: 50px;
    right: 0;
    z-index: 9999;
    background-color: #00000061;
    height: 300px;
    top: 0;
    color: white;
    bottom: 0;
    margin: auto;
    right: 50px;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 57%;
    line-height: 2;
}

header{
    margin-bottom: 50px;
}

header h1{
    color: #b79766 !important;
    font-family: headerFont;
    letter-spacing: 3px;
}

header p{
    font-size: 13px;
    color: #adadaf;
}

.login-container form input[type="text"],
.login-container form input[type="password"]{
    border: 1px solid transparent;
    border-bottom: 1px solid gray;
    margin-bottom: 25px;
    font-size: 12px;
    padding: 10px 0;
    border-radius: 0px;
}

.login-container form input[type="text"]:focus::placeholder,
.login-container form input[type="password"]:focus::placeholder{
    color:transparent;
}

.login-container form input[type="text"]:focus,
.login-container form input[type="password"]:focus{
    outline-style: none;
    box-shadow: none;
}

.button-container{
    margin: 0 auto;
    text-align: center;
    padding-top: 30px;
    display: inline-block;
    width: 100%;
}

.login-container form .button-container .button{
    background-color: #444;
    color: white;
    margin-bottom: 30px;
    border: 1px solid #444;
    font-size: 12px;
    border-radius: 6px;
    text-decoration: none;
    padding: 10px 10px;
    width: 120px;
}
.policy{
    text-align: center;
    padding: 50px;
}
.policy a{
    text-decoration: none;
    color: blue;
    font-size:12px;
}
nav.MuiBox-root{
    display: block !important;
}
nav.MuiBox-root{
    display: block !important;
}