@media (min-width: 600px){
    .MuiToolbar-root {
        min-height: 30px !important;
    }
    .pc-hidden{
        display: none !important;
    }
    .pc-ml-1{
        margin-left: 0.5em !important;
    }
    .publish_date .react-datepicker-wrapper, .publish_date #text{
        margin-left: 0.8em !important;
    }
    .publish_date #text{
        margin-left: 1em !important;
    }
}

@media (max-width: 576px){
    .welcome,.xs-hidden{
        display: none !important;
    }
}


@media (max-width: 576px){
    .welcome,.xs-hidden{
        display: none !important;
    }
    .create-form .card{
        padding-left: unset !important;
        padding-right: unset !important;
    }
    .detail .profile .status_btn_1,.detail .profile .status_btn_2{
        left: 65px !important;
        top: 260px !important;
    }
    .modal_cancel_btn{
        margin: unset !important;
    }
    #case_create_submit, #case_copy_delete{
        margin: 10px 5px 0 10px !important;
    }
    .list-item {
        flex: 1 1 calc(50% - 20px); /* Two items per row on medium screens */
      }
      .top-baner, .top-baner-detail{
        left: 30px !important;
        padding: unset !important;
    }
    select, input[type=text], input[type=number], input[type=time], input[type=password], input[type=email], textarea {
        border-radius: 10px !important;
        padding: 5px !important;
        border: 1px solid rgb(83, 83, 85) !important;
    }
    .filter_select select.filter{
        width: 100% !important;
    }
    .filter_select #list_length{
        width: unset !important;
    }
    .xs-disable-float{
        float: unset !important;
    }
    .react-datepicker-wrapper input[type=text]{
        padding: 2px 0px !important;
    }
    .chart-container canvas{
        width: 80% !important;
    }
    .pc-ml-1{
        margin-left: 0px !important;
    }
}



.mt_10{
    margin-top: 10rem !important;
}


.responsive-list {
   
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap */
  }
  
  .list-item,.list-item1 {
    margin: 5px;
    padding: 10px;
    border-radius: 5px;
    /* flex: 1 1 calc(33.333% - 20px);  */
    /* Three items per row */
    box-sizing: border-box;
    text-align: center;
  }
  
  
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .list-item {
        flex: 1 1 calc(25% - 20px); 
      /* flex: 1 1 calc(50% - 20px);  */
      /* Two items per row on medium screens */
    }
    .list-item1 {
        flex: 1 1 calc(20% - 20px); 
      /* flex: 1 1 calc(50% - 20px);  */
      /* Two items per row on medium screens */
    }
    .status-menu li {
        padding: 2px 2px !important;
        cursor: pointer;
        font-size: 12px;
    }
  }
  
  @media (max-width: 480px) {
    .list-item {
      flex: 1 1 calc(25% - 20px); /* One item per row on small screens */
    }
  }

